import React, { useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";

//jQuery initialization
import $ from "jquery";

//functions
import {
  _t,
  managePageHrefLink,
  managePageHrefLinkMobile,
  checkPermission,
  getSystemSettings,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";
import { SettingsContext } from "../../../../contexts/Settings";
import { UserContext } from "../../../../contexts/User";
import { NoPermission } from "../../../../imports/Pages";
const ReportSidebar = () => {
  const { t } = useTranslation();
  useEffect(() => {
    handleJquery();
  }, []);

  const { showManageStock,generalSettings } = useContext(SettingsContext);
  let { authUserInfo } = useContext(UserContext);

  //jQuery goes here
  const handleJquery = () => {
    var posHasSub = $(".fk-pos-nav__list-has-sub");
    var posSub = $(".fk-pos-nav__sub");
    $(".fk-pos-nav__list-has-sub > a").on("click", function (e) {
      e.preventDefault();
    });
    posHasSub.on("click", function () {
      $(this).find(posSub).slideDown();
      $(this).siblings().find(posSub).slideUp();
      $(this).addClass("active").siblings().removeClass("active");
    });
  };

  console.log(
    "report",
    checkPermission(authUserInfo.permissions, "Work Period Report")
  );

  return (
    <>
      {/* Navigation for Small Screen  */}
      <div className="d-lg-none">
        <div className="row">
          <div className="col-12">
            <div className="fk-sm-nav" data-simplebar>
              {/* Reports */}
              <ul className="t-list fk-sm-nav__bar flex-row">
                {authUserInfo && authUserInfo.details.user_type !== "staff" ? (
                  <>
                    {/* dashboard */}
                    {managePageHrefLinkMobile(
                      "/dashboard/reports",
                      _t(t("Dashboard"))
                    )}

                    {/*consolited dashboard */}
                    {authUserInfo && authUserInfo.details.user_type === "superAdmin" &&
                     getSystemSettings(generalSettings, "call_api") === "yes" && managePageHrefLinkMobile(
                      "/dashboard/consolidated-reports",
                      _t(t("Consolidate Dashboard"))
                    )}

                    {/*consolited dashboard */}
                    {authUserInfo && authUserInfo.details.user_type === "superAdmin" &&
                     getSystemSettings(generalSettings, "call_api") === "yes" && managePageHrefLinkMobile(
                      "/dashboard/consolidate-reports",
                      _t(t("Consolidate Report"))
                    )}

                    {/* daily */}
                    {authUserInfo.permissions !== null &&
                    checkPermission(authUserInfo.permissions, "Daily Report")
                      ? managePageHrefLinkMobile(
                          "/dashboard/daily-reports",
                          _t(t("Daily"))
                        )
                      : ""}

                    {/* monthly */}
                    {authUserInfo.permissions !== null &&
                    checkPermission(authUserInfo.permissions, "Monthly Report")
                      ? managePageHrefLinkMobile(
                          "/dashboard/monthly-reports",
                          _t(t("Monthly"))
                        )
                      : ""}
                  </>
                ) : null}

                {/* monthly */}
                {authUserInfo.permissions !== null &&
                checkPermission(authUserInfo.permissions, "Work Period Report")
                  ? managePageHrefLinkMobile(
                      "/dashboard/work-period-reports",
                      _t(t("Work Period Report"))
                    )
                  : ""}
                {/* monthly */}
                {authUserInfo.permissions !== null &&
                checkPermission(authUserInfo.permissions, "Sales Report")
                  ? managePageHrefLinkMobile(
                      "/dashboard/sales-reports",
                      _t(t("Sales Report"))
                    )
                  : ""}
                {/* monthly */}
                {authUserInfo.permissions !== null &&
                checkPermission(
                  authUserInfo.permissions,
                  "Sales Summary Report"
                )
                  ? managePageHrefLinkMobile(
                      "/dashboard/sales-summary-reports",
                      _t(t("Sales Summary Report"))
                    )
                  : ""}
                  
                {authUserInfo.permissions !== null &&
                checkPermission(
                  authUserInfo.permissions,
                  "Sales Summary Report"
                )
                  ? managePageHrefLinkMobile(
                      "/dashboard/menu-mix-reports",
                      _t(t("Menu Mix Report"))
                    )
                  : ""}

                  {authUserInfo.permissions !== null &&
                checkPermission(
                  authUserInfo.permissions,
                  "Callcenter Report"
                )
                  ? managePageHrefLinkMobile(
                      "/dashboard/call-center-reports",
                      _t(t("Call Center Report"))
                    )
                  : ""}

                {/* monthly */}
                {authUserInfo.permissions !== null &&
                checkPermission(authUserInfo.permissions, "Source Wise Report")
                  ? managePageHrefLinkMobile(
                      "/dashboard/source-reports",
                      _t(t("Source Wise Report"))
                    )
                  : ""}
                {/* monthly */}
                {authUserInfo.permissions !== null &&
                checkPermission(
                  authUserInfo.permissions,
                  "Customer Order History Report"
                )
                  ? managePageHrefLinkMobile(
                      "/dashboard/customer-order-reports",
                      _t(t("Customer Order Report"))
                    )
                  : ""}
                {authUserInfo.permissions !== null &&
                checkPermission(authUserInfo.permissions, "Food Panda Report")
                  ? managePageHrefLinkMobile(
                      "/dashboard/food-panda-reports",
                      _t(t("Food Panda Report"))
                    )
                  : ""}
                  {authUserInfo.permissions !== null &&
                    checkPermission(authUserInfo.permissions, "Rider Report")
                      ? managePageHrefLinkMobile(
                          "/dashboard/rider-reports",
                          _t(t("Rider Report"))
                        )
                      : ""}
                    {authUserInfo.permissions !== null &&
                    checkPermission(authUserInfo.permissions, "Income Statement Report")
                      ? managePageHrefLinkMobile(
                          "/dashboard/income-statement-reports",
                          _t(t("Income Statement Report"))
                        )
                      : ""}
                {authUserInfo.permissions !== null &&
                    checkPermission(authUserInfo.permissions, "Expense Report")
                      ? managePageHrefLinkMobile(
                          "/dashboard/expense-reports",
                          _t(t("Expense Report"))
                        )
                      : ""}
                {authUserInfo.permissions !== null &&
                checkPermission(authUserInfo.permissions, "Meezan Report")
                  ? managePageHrefLinkMobile(
                      "/dashboard/meezan-reports",
                      _t(t("Card Discount Report"))
                    )
                  : ""}
                {authUserInfo.permissions !== null &&
                checkPermission(authUserInfo.permissions, "Cancel Order Report")
                  ? managePageHrefLinkMobile(
                      "/dashboard/cancel-order-reports",
                      _t(t("Cancel Order Report"))
                    )
                  : ""}
                {authUserInfo.permissions !== null &&
                checkPermission(authUserInfo.permissions, "Cancel Item Report")
                  ? managePageHrefLinkMobile(
                      "/dashboard/cancel-made-unmade-reports",
                      _t(t("Cancel Item Made/Unmade Report"))
                    )
                  : ""}
                {authUserInfo.permissions !== null &&
                checkPermission(authUserInfo.permissions, "Hourly Sales Report")
                  ? managePageHrefLinkMobile(
                      "/dashboard/hourly-sales-reports",
                      _t(t("Hourly Sales Report"))
                    )
                  : ""}
               
                {authUserInfo.permissions !== null &&
                checkPermission(
                  authUserInfo.permissions,
                  "Daily Cash Sales Report"
                )
                  ? managePageHrefLinkMobile(
                      "/dashboard/daily-cash-sales-reports",
                      _t(t("Daily Cash Sales Report"))
                    )
                  : ""}

                {/* yearly */}
                {/* {managePageHrefLink(
                  "/dashboard/yearly-reports",
                  _t(t("Yearly"))
                )} */}

                {/* food item wise */}
                {authUserInfo.permissions !== null &&
                checkPermission(
                  authUserInfo.permissions,
                  "Item Food Cost Report"
                )
                  ? managePageHrefLinkMobile(
                      "/dashboard/food-items-reports",
                      _t(t("Item Food Cost"))
                    )
                  : ""}

                {/* food group wise */}
                {
                  //   authUserInfo.permissions !== null &&
                  //   checkPermission(authUserInfo.permissions, "Group Wise Report") ? (
                  //   managePageHrefLinkMobile(
                  //   "/dashboard/food-group-reports",
                  //   _t(t("Group Wise"))
                  // )):
                  // ""
                }

                {/* branch wise */}
                {
                  //   authUserInfo.permissions !== null &&
                  //   checkPermission(authUserInfo.permissions, "Branch Wise Report") ? (
                  //   managePageHrefLinkMobile(
                  //   "/dashboard/branch-reports",
                  //   _t(t("Branch Wise"))
                  // )):
                  // ""
                }

                {/* pos user wise */}
                {
                  //   authUserInfo.permissions !== null &&
                  //   checkPermission(authUserInfo.permissions, "Pos User Wise Report") ? (
                  //     managePageHrefLinkMobile(
                  //   "/dashboard/pos-user-reports",
                  //   _t(t("Pos User Wise"))
                  // )):
                  // ""
                }

                {/* food item wise */}
                {
                  //   authUserInfo.permissions !== null &&
                  //   checkPermission(authUserInfo.permissions, "Department Wise Report") ? (
                  //     managePageHrefLinkMobile(
                  //   "/dashboard/dept-tag-reports",
                  //   _t(t("Department Wise"))
                  // )):
                  // ""
                }

                {/* Service charge wise */}
                {
                  //   authUserInfo.permissions !== null &&
                  //   checkPermission(authUserInfo.permissions, "Service Charge Wise Report") ? (
                  //   managePageHrefLinkMobile(
                  //   "/dashboard/service-charge-reports",
                  //   _t(t("Service Charge Wise"))
                  // )):
                  // ""
                }

                {/* discount charge wise */}
                {
                  //   authUserInfo.permissions !== null &&
                  //   checkPermission(authUserInfo.permissions, "Discount Wise Report") ? (
                  //   managePageHrefLinkMobile(
                  //   "/dashboard/discount-reports",
                  //   _t(t("discount Wise"))
                  // )):
                  // ""
                }

                {/* {managePageHrefLinkMobile(
                  "/dashboard/food-stock",
                  _t(t("Food Stock"))
                )}
                {managePageHrefLinkMobile(
                  "/dashboard/ingredient-stock",
                  _t(t("Ingredient Stock"))
                )} */}

                {/* discount charge wise */}
                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Discount Wise Report"
                  ) ?
                  managePageHrefLinkMobile(
                  "/dashboard/discount-reports",
                  _t(t("Discount Wise"))
                ) : ""}

                {/* fg ledger */}
                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Finished Goods Report"
                  ) ?
                  managePageHrefLinkMobile(
                  "/dashboard/finished-goods-ledger-reports",
                  _t(t("Finished Goods Ledger"))
                ) : ""}

                {/* inventory */}
                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Inventory Report"
                  ) ?
                  managePageHrefLinkMobile(
                  "/dashboard/inventory-reports",
                  _t(t("Inventory Report"))
                ) : ""}

                {/* stock ledger */}
                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Stock Ledger Report"
                  ) ?
                  managePageHrefLinkMobile(
                  "/dashboard/stock-ledger-reports",
                  _t(t("Stock Ledger"))
                ):""}

                {/* stock consumption */}
                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Stock Consumption Report"
                  ) ?
                  managePageHrefLinkMobile(
                  "/dashboard/stock-consumption-reports",
                  _t(t("Stock Consumption"))
                ):""}

              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* Navigation for Small Screen  End*/}

      {/* Navigation for large Screen  */}
      <div className="d-none d-lg-block">
        <div className="fk-scroll--pos-menu" data-simplebar>
          <ul className="t-list fk-pos-nav">
            {/* Sales */}
            <li
              className={`fk-pos-nav__list fk-pos-nav__list-has-sub ${
                window.location.pathname.includes("reports") ? "active" : ""
              }`}
            >
              <a
                className="w-100 t-text-dark t-heading-font btn btn-outline-danger font-weight-bold text-uppercase rounded-0 text-left"
                rel="noopener noreferrer"
                href="#"
              >
                {_t(t("Sale's Reports"))}
              </a>
              <ul className="t-list fk-pos-nav__sub t-bg-white list-group">
                {authUserInfo && authUserInfo.details.user_type !== "staff" ? (
                  <>
                    {/* dashboard */}
                    {managePageHrefLink(
                      "/dashboard/reports",
                      _t(t("Dashboard"))
                    )}

                     {/*consolited dashboard */}
                     {authUserInfo && authUserInfo.details.user_type === "superAdmin" &&
                      getSystemSettings(generalSettings, "call_api") === "yes" && managePageHrefLink(
                      "/dashboard/consolidated-reports",
                      _t(t("Consolidate Dashboard"))
                    )}

                      {/*consolited dashboard */}
                      {authUserInfo && authUserInfo.details.user_type === "superAdmin" &&
                       getSystemSettings(generalSettings, "call_api") === "yes" && managePageHrefLink(
                        "/dashboard/consolidate-reports",
                        _t(t("Consolidate Report"))
                      )}

                    {/* daily */}

                    {authUserInfo.permissions !== null &&
                    checkPermission(authUserInfo.permissions, "Daily Report")
                      ? managePageHrefLink(
                          "/dashboard/daily-reports",
                          _t(t("Daily"))
                        )
                      : ""}

                    {/* monthly */}
                    {authUserInfo.permissions !== null &&
                    checkPermission(authUserInfo.permissions, "Monthly Report")
                      ? managePageHrefLink(
                          "/dashboard/monthly-reports",
                          _t(t("Monthly"))
                        )
                      : ""}
                  </>
                ) : null}
                {/* monthly */}
                {authUserInfo.permissions !== null &&
                checkPermission(authUserInfo.permissions, "Work Period Report")
                  ? managePageHrefLink(
                      "/dashboard/work-period-reports",
                      _t(t("Work Period Report"))
                    )
                  : ""}
                {/* monthly */}
                {authUserInfo.permissions !== null &&
                checkPermission(authUserInfo.permissions, "Sales Report")
                  ? managePageHrefLink(
                      "/dashboard/sales-reports",
                      _t(t("Sales Report"))
                    )
                  : ""}
                {authUserInfo.permissions !== null &&
                checkPermission(
                  authUserInfo.permissions,
                  "Sales Summary Report"
                )
                  ? managePageHrefLink(
                      "/dashboard/sales-summary-reports",
                      _t(t("Sales Summary Report"))
                    )
                  : ""}
                  {authUserInfo.permissions !== null &&
                checkPermission(
                  authUserInfo.permissions,
                  "Sales Summary Report"
                )
                  ? managePageHrefLink(
                      "/dashboard/menu-mix-reports",
                      _t(t("Menu Mix Report"))
                    )
                  : ""}
                  {authUserInfo.permissions !== null &&
                checkPermission(
                  authUserInfo.permissions,
                  "Callcenter Report"
                )
                  ? managePageHrefLink(
                      "/dashboard/call-center-reports",
                      _t(t("Call Center Report"))
                    )
                  : ""}
                {/* monthly */}
                {authUserInfo.permissions !== null &&
                checkPermission(authUserInfo.permissions, "Source Wise Report")
                  ? managePageHrefLink(
                      "/dashboard/source-reports",
                      _t(t("Source Wise Report"))
                    )
                  : ""}
                {/* monthly */}
                {authUserInfo.permissions !== null &&
                checkPermission(
                  authUserInfo.permissions,
                  "Customer Order History Report"
                )
                  ? managePageHrefLink(
                      "/dashboard/customer-order-reports",
                      _t(t("Customer Order Report"))
                    )
                  : ""}
               
                  {authUserInfo.permissions !== null &&
                    checkPermission(authUserInfo.permissions, "Food Panda Report")
                      ? managePageHrefLink(
                          "/dashboard/food-panda-reports",
                          _t(t("Food Panda Report"))
                        )
                      : ""}
                      {authUserInfo.permissions !== null &&
                        checkPermission(authUserInfo.permissions, "Rider Report")
                          ? managePageHrefLink(
                              "/dashboard/rider-reports",
                              _t(t("Rider Report"))
                            )
                          : ""}
                    {authUserInfo.permissions !== null &&
                    checkPermission(authUserInfo.permissions, "Income Statement Report")
                      ? managePageHrefLink(
                          "/dashboard/income-statement-reports",
                          _t(t("Income Statement Report"))
                        )
                      : ""}
                {authUserInfo.permissions !== null &&
                    checkPermission(authUserInfo.permissions, "Expense Report")
                      ? managePageHrefLink(
                          "/dashboard/expense-reports",
                          _t(t("Expense Report"))
                        )
                      : ""}
                {authUserInfo.permissions !== null &&
                checkPermission(authUserInfo.permissions, "Meezan Report")
                  ? managePageHrefLink(
                      "/dashboard/meezan-reports",
                      _t(t("Card Discount Report"))
                    )
                  : ""}
                {authUserInfo.permissions !== null &&
                checkPermission(authUserInfo.permissions, "Cancel Order Report")
                  ? managePageHrefLink(
                      "/dashboard/cancel-order-reports",
                      _t(t("Cancel Order Report"))
                    )
                  : ""}
                {authUserInfo.permissions !== null &&
                checkPermission(authUserInfo.permissions, "Cancel Item Report")
                  ? managePageHrefLink(
                      "/dashboard/cancel-made-unmade-reports",
                      _t(t("Cancel Item Made/Unmade Report"))
                    )
                  : ""}
                {authUserInfo.permissions !== null &&
                checkPermission(authUserInfo.permissions, "Hourly Sales Report")
                  ? managePageHrefLink(
                      "/dashboard/hourly-sales-reports",
                      _t(t("Hourly Sales Report"))
                    )
                  : ""}
               
                {authUserInfo.permissions !== null &&
                checkPermission(
                  authUserInfo.permissions,
                  "Daily Cash Sales Report"
                )
                  ? managePageHrefLink(
                      "/dashboard/daily-cash-sales-reports",
                      _t(t("Daily Cash Sales Report"))
                    )
                  : ""}

                {/* yearly */}
                {/* {managePageHrefLink(
                  "/dashboard/yearly-reports",
                  _t(t("Yearly"))
                )} */}

                {/* food item wise */}
                {authUserInfo.permissions !== null &&
                checkPermission(
                  authUserInfo.permissions,
                  "Item Food Cost Report"
                )
                  ? managePageHrefLink(
                      "/dashboard/food-items-reports",
                      _t(t("Item Food Cost"))
                    )
                  : ""}

                {/* food group wise */}
                {
                  //   authUserInfo.permissions !== null &&
                  //   checkPermission(authUserInfo.permissions, "Group Wise Report") ? (
                  //   managePageHrefLink(
                  //   "/dashboard/food-group-reports",
                  //   _t(t("Group Wise"))
                  // )):
                  // ""
                }

                {/* branch wise */}
                {
                  //   authUserInfo.permissions !== null &&
                  //   checkPermission(authUserInfo.permissions, "Branch Wise Report") ? (
                  //   managePageHrefLink(
                  //   "/dashboard/branch-reports",
                  //   _t(t("Branch Wise"))
                  // )):
                  // ""
                }

                {/* pos user wise */}
                {
                  //   authUserInfo.permissions !== null &&
                  //   checkPermission(authUserInfo.permissions, "Pos User Wise Report") ? (
                  //   managePageHrefLink(
                  //   "/dashboard/pos-user-reports",
                  //   _t(t("Pos User Wise"))
                  // )):
                  // ""
                }

                {/* food item wise */}
                {
                  //   authUserInfo.permissions !== null &&
                  //   checkPermission(authUserInfo.permissions, "Department Wise Report") ? (
                  //   managePageHrefLink(
                  //   "/dashboard/dept-tag-reports",
                  //   _t(t("Department Wise"))
                  // )):
                  // ""
                }

                {/* Service charge wise */}
                {
                  //   authUserInfo.permissions !== null &&
                  //   checkPermission(authUserInfo.permissions, "Service Charge Wise Report") ? (
                  //   managePageHrefLink(
                  //   "/dashboard/service-charge-reports",
                  //   _t(t("Service Charge Wise"))
                  // )):
                  // ""
                }

                {/* discount charge wise */}
                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Discount Wise Report"
                  ) ?
                  managePageHrefLink(
                  "/dashboard/discount-reports",
                  _t(t("Discount Wise"))
                ) : ""}

                {/* fg ledger */}
                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Finished Goods Report"
                  ) ?
                  managePageHrefLink(
                  "/dashboard/finished-goods-ledger-reports",
                  _t(t("Finished Goods Ledger"))
                ) : ""}

                {/* inventory */}
                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Inventory Report"
                  ) ?
                  managePageHrefLink(
                  "/dashboard/inventory-reports",
                  _t(t("Inventory Report"))
                ) : ""}

                {/* stock ledger */}
                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Stock Ledger Report"
                  ) ?
                  managePageHrefLink(
                  "/dashboard/stock-ledger-reports",
                  _t(t("Stock Ledger"))
                ):""}

                {/* stock consumption */}
                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Stock Consumption Report"
                  ) ?
                  managePageHrefLink(
                  "/dashboard/stock-consumption-reports",
                  _t(t("Stock Consumption"))
                ):""}
                
              </ul>
            </li>
            {/* Stock */}
            {/* {showManageStock ? (
              <li
                className={`fk-pos-nav__list fk-pos-nav__list-has-sub ${
                  window.location.pathname.includes("stock") ? "active" : ""
                }`}
              >
                <a
                  className="w-100 t-text-dark t-heading-font btn btn-outline-danger font-weight-bold text-uppercase rounded-0 text-left"
                  rel="noopener noreferrer"
                  href="#"
                >
                  {_t(t("Stocks's Reports"))}
                </a>
                <ul className="t-list fk-pos-nav__sub t-bg-white list-group">
                  {managePageHrefLink(
                    "/dashboard/food-stock",
                    _t(t("Food Stock"))
                  )}
                  {managePageHrefLink(
                    "/dashboard/ingredient-stock",
                    _t(t("Ingredient Stock"))
                  )}
                  
                </ul>
              </li>
            ) : (
              ""
            )} */}
          </ul>
        </div>
      </div>
      {/* Navigation for large Screen End */}
    </>
  );
};

export default ReportSidebar;
