import React, { useEffect, useContext, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  tableLoading,
  formatPrice,
  getSystemSettings,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from 'moment';
import Moment from "react-moment";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useReactToPrint } from "react-to-print";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//pages & includes
import ReportSidebar from "./ReportSidebar";

//context consumer
import { SettingsContext } from "../../../../contexts/Settings";
import { RestaurantContext } from "../../../../contexts/Restaurant";
import { UserContext } from "../../../../contexts/User";

const HourlySalesReport = () => {
  const { t } = useTranslation();
  const history = useHistory();
  //getting context values here
  let { loading, setLoading, dataPaginating, generalSettings } =
    useContext(SettingsContext);
  let { branchForSearch } = useContext(RestaurantContext);
  const { authUserInfo, partyForSearch } = useContext(UserContext);

  const reportType = [
    {
      id:1,
      name: "Paid",
      value: "paid"
    },
  {
    id:2,
    name: "Refund",
    value: "refund"
  },
  {
    id:3,
    name: "Cancelled",
    value: "cancelled"
  },
]

  let [newHourlySalesReport, setNewHourlySalesReport] = useState(null);

  //all data
  // const [dineInData, setDineInData] = useState(null);
  // const [DeliveryData, setDeliveryData] = useState(null);
  // const [takeawayData, setTakeawayData] = useState(null);
  // const [grandTotalData, setGrandTotalData] = useState(null);
  //all data
  const [reportDataHead, setReportDataHead] = useState(null);
  const [reportData, setReportData] = useState(null);
  const [reportDataSales, setReportDataSales] = useState(null);
  const [thermalHead,setThermalHead]=useState(null);
  const [thermalTransaction,setThermalTransaction]=useState(null);
  const [thermalSales,setThermalSales]=useState(null);
  const componentRef = useRef();
  const componentRef1 = useRef();
  const componentRefThermal = useRef();
  //settle order
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [generatedReport, setGeneratedReport] = useState(false);
  const [generatedReport1, setGeneratedReport1] = useState(false);
  const [siteName, setSiteName] = useState("");
  //useEffect == componentDidMount()
  const [branchSearch, setBranchSearch] = useState(null);

  useEffect(() => {
    setSiteName(getSystemSettings(generalSettings, "siteName"));
    if (authUserInfo.details.user_type === "staff") {
      setBranchSearch(
        branchForSearch.filter(
          (branch) => branch.id === authUserInfo.details.branch_id
        )
      );
    } else {
      setBranchSearch(branchForSearch);
    }
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [generalSettings]);

  //Print Report
  const ReportPrint = useRef();

  const handlePrintBtn = useReactToPrint({
    content: () => ReportPrint.current,
  });

  const handleBranch = (branch) => {
    // setDineInData(null);
    // setDeliveryData(null);
    // setTakeawayData(null);
    // setGrandTotalData(null);

    setNewHourlySalesReport({
      ...newHourlySalesReport,
      branch,
    });
    // setBranch(branch);
  };

  const handleType = (type) => {
    // setDineInData(null);
    // setDeliveryData(null);
    // setTakeawayData(null);
    // setGrandTotalData(null);

    setNewHourlySalesReport({
      ...newHourlySalesReport,
      type,
    });
    // setBranch(branch);
  };

   //get BranchWise reports filter
   const getHourlyReport = () => {
    setReportData(null);
    setReportDataHead(null)
    setReportDataSales(null)
    if (startDate !== null && endDate!==null) {
      setLoading(true);

      var fromDate = moment(startDate).format("YYYY-MM-DD");
      var toDate = moment(endDate).format("YYYY-MM-DD");
     
      const url = BASE_URL + "/settings/get-hourly-sales-report";

      let formData = new FormData();
      formData.append("fromDate", fromDate);
      formData.append("toDate", toDate);
      formData.append("branch_id", newHourlySalesReport?.branch?.id ? newHourlySalesReport?.branch?.id : "");
      formData.append("order_type", newHourlySalesReport?.type ? newHourlySalesReport.type?.name : "");

      return axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          // console.log("check data==>",res.data)
          setReportDataHead(res.data[0])
          setReportData(res.data[1]);
          setReportDataSales(res.data[2])
          setLoading(false);
          console.log("Component ", componentRef);
          setGeneratedReport(true);
          componentRef1.current.handleDownload();
          setReportData(null);
          setReportDataHead(null)
          setReportDataSales(null)
          setGeneratedReport(false)
        })
        .catch((error) => {
          setLoading(false);
          setReportData(null);
          setReportDataHead(null);
          setGeneratedReport(false)
          setReportDataSales(null)
        });
    } else {
      toast.error(
        `${_t(t("Please select from date, to date to generate report"))}`,
        {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }
      );
    }
  };

  const getBranchWiseSelected = () => {
    setReportData(null);
    setReportDataHead(null);
    setReportDataSales(null)
    if (startDate !== null && endDate!==null) {
      setLoading(true);
      var fromDate = moment(startDate).format("YYYY-MM-DD");
      var toDate = moment(endDate).format("YYYY-MM-DD");

      const url = BASE_URL + "/settings/get-hourly-party-sales-report";

      let formData = new FormData();
      formData.append("fromDate", fromDate);
      formData.append("toDate", toDate);
      formData.append("branch_id", newHourlySalesReport?.branch?.id ? newHourlySalesReport?.branch?.id : "");
      formData.append("order_type", newHourlySalesReport?.type ? newHourlySalesReport.type?.name : "");

      return axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          //setReportDataHead(res.data[0])
          setReportData(res.data[0]);
          setReportDataSales(res.data[1])
          setLoading(false);
          console.log("Component ", componentRef);
          setGeneratedReport1(true);
          componentRef.current.handleDownload();
          setReportData(null);
          setReportDataHead(null);
          setGeneratedReport1(false)
          setReportDataSales(null)
        })
        .catch((error) => {
          setLoading(false);
          setReportData(null);
          setReportDataHead(null);
          setGeneratedReport1(false)
          setReportDataSales(null)
        });
    } else {
      toast.error(
        `${_t(t("Please select from date, to date to generate report"))}`,
        {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }
      );
    }
  };

  const thermalPrint = useReactToPrint({
    content: () => componentRefThermal.current,
  });

  const handleSetParty = (items) =>{
    setNewHourlySalesReport({
      ...newHourlySalesReport,
      partyArray:items
    })
  }  

  const getThermalReport=()=>{
    setThermalHead(null)
    setThermalTransaction(null);
    setThermalSales(null)
    if (startDate !== null && endDate!==null && newHourlySalesReport?.branch) {
      setLoading(true);
      var fromDate = moment(startDate).format("YYYY-MM-DD");
      var toDate = moment(endDate).format("YYYY-MM-DD");
      let party_id = newHourlySalesReport?.partyArray && newHourlySalesReport?.partyArray.map((item)=>item.id);      
     
      const url = BASE_URL + "/settings/get-hourly-sales-report";      

      let formData = new FormData();
      formData.append("fromDate", fromDate);
      formData.append("toDate", toDate);
      formData.append("branch_id", newHourlySalesReport?.branch?.id ? newHourlySalesReport?.branch?.id : "");
      formData.append("order_type", newHourlySalesReport?.type ? newHourlySalesReport.type?.name : "");
      formData.append("party_id", party_id ? party_id : []);
      formData.append("is_thermal", true);

      return axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {   
          // console.log("check response==>",res.data);       
          setThermalHead(res.data[0])
          setThermalTransaction(res.data[1]);
          setThermalSales(res.data[2])
          setLoading(false);                    
          thermalPrint();                    
        })
        .catch((error) => {
          setLoading(false);
          setThermalTransaction(null);
          setThermalHead(null);          
          setThermalSales(null)
        });                
    } else {
      toast.error(
        `${_t(t("Please select From date, To date and Branch to generate report"))}`,
        {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }
      );
    }    
  }
  //get BranchWise reports filter
  // old report data
  // console.log("newHourlySalesReport ", newHourlySalesReport);
  // const getBranchWiseSelected = () => {
  //   if (newHourlySalesReport?.branch?.id !== null && startDate !== null) {
  //     setLoading(true);

  //     var fromDate = moment(startDate).format("YYYY-MM-DD");

  //     const url = BASE_URL + "/settings/get-hourly-sales-report";

  //     let formData = new FormData();
  //     formData.append("fromDate", fromDate);
  //     formData.append("branch_id", newHourlySalesReport?.branch?.id);

  //     return axios
  //       .post(url, formData, {
  //         headers: { Authorization: `Bearer ${getCookie()}` },
  //       })
  //       .then((res) => {
  //         console.log(
  //           "HourlySalesReportResult",
  //           res.data[0],
  //           res.data[1],
  //           res.data[2],
  //           res.data[3]
  //         );
  //         setDineInData(res.data[0]);
  //         setDeliveryData(res.data[1]);
  //         setTakeawayData(res.data[2]);
  //         setGrandTotalData(res.data[3]);

  //         setLoading(false);
  //         console.log("Component ", componentRef);
  //         setGeneratedReport(true);
  //         componentRef.current.handleDownload();
  //         setNewHourlySalesReport(null);
  //       })
  //       .catch((error) => {
  //         setLoading(false);
  //       });
  //   } else {
  //     toast.error(
  //       `${_t(t("Please select from date, branch and generate report"))}`,
  //       {
  //         position: "bottom-center",
  //         closeButton: false,
  //         autoClose: 10000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         className: "text-center toast-notification",
  //       }
  //     );
  //   }
  // };

  // let dineingross = 0;
  // let dineinsales = 0;
  // let dineinchecks = 0;
  // let deliverygross = 0;
  // let deliverysales = 0;
  // let deliverychecks = 0;
  // let takeawaygross = 0;
  // let takeawaysales = 0;
  // let takeawaychecks = 0;
  // let grandgross = 0;
  // let grandsales = 0;
  // let grandchecks = 0;
  let g_total = 0;
  let G_total = 0;

  let p_total = 0;
  let P_total = 0;

  return (
    <>
      <Helmet>
        <title>{_t(t("Hourly Sales Report"))}</title>
      </Helmet>
      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="download-table-xls-button btn btn-success float-right mr-3 invisible"
        table="table-to-xls"
        filename="Party Wise Sales Report"
        sheet="sheet 1"
        buttonText="Sales Details"
        ref={componentRef}
      />

      <ReactHTMLTableToExcel
      id="test-table-xls-button"
      className="download-table-xls-button btn btn-success float-right mr-3 invisible"
      table="table-to-hour"
      filename="Hourly Sales Report"
      sheet="sheet 1"
      buttonText="Sales Detail"
      ref={componentRef1}
      />

       {/* Print Button */}
       <div className="d-none">
       <div>
         {/*<style type="text/css" media="print">
           {
             "\
         @page { size: landscape; }\
       "
           }
          </style>*/}
         <div className="fk-print">
           <div className="container">
             <div className="row">
               <div className="col-12">
                 {reportData !== null &&
                   reportData !== undefined &&
                   generatedReport === true && (
                     <>
                       <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                         {console.log("reportData", reportData)}
                         <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                           <table
                             className="table table-bordered table-hover min-table-height mt-3"
                             id="table-to-hour"
                           >
                             <div className="page-break"></div>
                             <thead className="align-middle">
                               <tr style={{ border: "0px" }}>
                                 <th colspan="26" style={{ border: "0px" }}>
                                   <div className="row">
                                     <div className="col-12">
                                       <h3 className="text-center mb-0">
                                         {siteName}
                                       </h3>
                                       <h3 className="text-center mt-0">
                                         Hourly Summary Report
                                       </h3>
                                       <p className="text-center">
                                         FROM&nbsp;
                                         <Moment format="LL">
                                           {startDate}
                                         </Moment>
                                         &nbsp;TO&nbsp;
                                         <Moment format="LL">{endDate}</Moment>
                                       </p>
                                     </div>
                                   </div>
                                 </th>
                               </tr>
                               <tr></tr>
                               <tr>
                                <th 
                                scope="row"
                                className="lg-text text-capitalize align-middle text-center"
                                >
                                 Branch Name
                                </th>
                                {
                                  Object.keys(reportDataHead).map((head)=>{
                                    return(
                                      <th
                                      scope="row"
                                      className="lg-text text-capitalize align-middle text-center"
                                      >
                                      {head}
                                      </th>
                                    )
                                  })
                                }
                                <th 
                                scope="row"
                                className="lg-text text-capitalize align-middle text-center"
                                >
                                 Total
                                </th>
                               </tr>
                             </thead>
                             <tbody className="align-middle">
                               {/* loop here*/}
                               <tr>
                                <th colspan="26">No of Orders</th>
                               </tr>
                               {reportData && Object.keys(reportData).map(
                                 (group_name, index) => {
                                   let total = 0;
                                   return (
                                     <div key={index}>
                                       <tr>
                                         <th
                                           scope="row"
                                           className="lg-text text-capitalize align-middle text-center"
                                         >
                                           {group_name}
                                         </th>
                                       {Object.entries(reportData[group_name]).map(
                                         (item, i) => {
                                           total += parseFloat(item[1]);
                                           g_total += parseFloat(item[1]);
                                           return (
                                              <td
                                              scope="row"
                                              className="xsm-text text-capitalize align-middle text-center"
                                              >{item[1]}</td>
                                            );
                                          }
                                          )}
                                          <th>
                                           {formatPrice(total)}
                                          </th>
                                        </tr>
                                     </div>
                                   );
                                 }
                               )}
                               <tr></tr>
                               <tr>
                                <td colSpan="24"></td>
                                <th>Grand Total</th>
                                <th>{formatPrice(g_total)}</th>
                               </tr> 
                               <tr></tr>
                               {/* sales wise table */}
                               <tr>
                               <th 
                               scope="row"
                               className="lg-text text-capitalize align-middle text-center"
                               >
                                Branch Name
                               </th>
                               {reportDataHead &&
                                 Object.keys(reportDataHead).map((head)=>{
                                   return(
                                     <th
                                     scope="row"
                                     className="lg-text text-capitalize align-middle text-center"
                                     >
                                     {head}
                                     </th>
                                   )
                                 })
                               }
                               <th 
                               scope="row"
                               className="lg-text text-capitalize align-middle text-center"
                               >
                                Total
                               </th>
                              </tr>
                               <tr>
                               <th colspan="26">Hourly Sales</th>
                              </tr>
                                {reportData && Object.keys(reportData).map(
                                  (group_name, index) => {
                                   let total = 0;
                                    return (
                                      <div key={index}>
                                        <tr>
                                          <th
                                            scope="row"
                                            className="lg-text text-capitalize align-middle text-center"
                                          >
                                            {group_name}
                                          </th>
                                        {Object.entries(reportDataSales[group_name]).map(
                                          (item, i) => {
                                            total += parseFloat(item[1]);
                                            G_total += parseFloat(item[1]);
                                            return (
                                               <td
                                               scope="row"
                                               className="xsm-text text-capitalize align-middle text-center"
                                               >{item[1]}</td>
                                             );
                                           }
                                           )}
                                           <th>
                                            {formatPrice(total)}
                                           </th>
                                         </tr>
                                      </div>
                                    );
                                  }
                                )}
                                <tr></tr>
                                <tr>
                                 <td colSpan="24"></td>
                                 <th>Grand Total</th>
                                 <th>{formatPrice(G_total)}</th>
                                </tr> 
                             </tbody>
                             <tfoot style={{ border: "0px" }}>
                               <tr style={{ border: "0px" }}>
                                 <td
                                   colspan="7"
                                   className="text-center"
                                   style={{ border: "0px" }}
                                 >
                                   <h5 className="mt-3">
                                     Powered by indolj.pk
                                   </h5>
                                 </td>
                               </tr>
                             </tfoot>
                           </table>
                         </div>
                       </div>
                     </>
                   )}
               </div>
             </div>
           </div>
         </div>
       </div>
     </div>

      {/* Print Button */}
      <div className="d-none">
        <div>
         
          <div className="fk-print">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {reportData !== null &&
                    reportData !== undefined &&
                    generatedReport1 === true && (
                      <>
                        <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                          <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                            <table
                              className="table table-bordered table-hover min-table-height mt-3"
                              id="table-to-xls"
                            >
                              <div className="page-break"></div>
                              <thead className="align-middle">
                                <tr style={{ border: "0px" }}>
                                  <th colspan="9" style={{ border: "0px" }}>
                                    <div className="row">
                                      <div className="col-12">
                                        <h3 className="text-center mb-0">
                                          {siteName}
                                        </h3>
                                        <h3 className="text-center mt-0">
                                          Hourly Party Wise Summary Report
                                        </h3>
                                        <p className="text-center">
                                          FROM&nbsp;
                                          <Moment format="LL">
                                            {startDate}
                                          </Moment>
                                          &nbsp;TO&nbsp;
                                          <Moment format="LL">{endDate}</Moment>
                                        </p>
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                                <tr></tr>
                                <tr>
                                 <th>Branch Name</th>
                                 <th>Party Name</th>
                                 <th>10 am till 2 pm</th>
                                 <th>2 pm till 6 pm</th>
                                 <th>6 pm till 10 pm</th>
                                 <th>10 pm till 2 am</th>
                                 <th>2 am till 6 am</th>
                                 <th>6 am till 10 am</th>
                                 <th>Total</th>
                                </tr>
                              </thead>
                              <tbody className="align-middle">
                                {/* loop here*/}
                                <tr>
                                <th colspan="9">No of Order</th>
                               </tr>
                                {Object.keys(reportData).map(
                                  (group_name, index) => {
                                    let data = 0;
                                    return (
                                      <div key={index}>
                                        <tr>
                                          <th
                                            scope="row"
                                            className="lg-text text-capitalize align-middle text-center"
                                          >
                                            {group_name}
                                          </th>
                                        </tr>
                                        {Object.keys(reportData[group_name]).map(
                                          (item, i) => {
                                            let total = 0;
                                            return (
                                              <tr>
                                                <td></td>
                                                <th>
                                                {item}
                                                </th>
                                                {
                                                  Object.values(reportData[group_name][item]).map((value)=>{
                                                    total += parseFloat(value);
                                                    p_total += parseFloat(value);
                                                    data += parseFloat(value);
                                                    return (
                                                       <td>
                                                         {value}
                                                       </td>
                                                    )
                                                  })
                                                }
                                                <th>{formatPrice(total)}</th>
                                              </tr>
                                            );
                                          }
                                        )}
                                        <tr>
                                        <th colSpan="7"></th>
                                        <th>Branch Total</th>
                                        <th>{formatPrice(data)}</th>
                                        </tr>
                                      </div>
                                    );
                                  }
                                )}
                                <tr></tr>
                                <tr>
                                 <th colSpan="7"></th>
                                 <th>Grand Total</th>
                                 <th>{formatPrice(p_total)}</th>
                                </tr>
                               <tr></tr>
                               <tr></tr>
                               {/* sales wise table */}
                               <tr>
                               <th>Branch Name</th>
                               <th>Party Name</th>
                               <th>10 am till 2 pm</th>
                               <th>2 pm till 6 pm</th>
                               <th>6 pm till 10 pm</th>
                               <th>10 pm till 2 am</th>
                               <th>2 am till 6 am</th>
                               <th>6 am till 10 am</th>
                               <th>Total</th>
                              </tr>
                               <tr>
                               <th colspan="9">Party Wise Sales</th>
                               </tr>
                                {Object.keys(reportDataSales).map(
                                  (group_name, index) => {
                                    let data = 0
                                    return (
                                      <div key={index}>
                                        <tr>
                                          <th
                                            scope="row"
                                            className="lg-text text-capitalize align-middle text-center"
                                          >
                                            {group_name}
                                          </th>
                                        </tr>
                                        {Object.keys(reportDataSales[group_name]).map(
                                          (item, i) => {
                                            let total = 0;
                                            return (
                                              <tr>
                                              <td></td>
                                                <th>
                                                {item}
                                                </th>
                                                {
                                                  Object.values(reportDataSales[group_name][item]).map((value)=>{
                                                    total += parseFloat(value)
                                                    P_total += parseFloat(value);
                                                    data += parseFloat(value);
                                                    return (
                                                       <td>
                                                         {value}
                                                       </td>
                                                    )
                                                  })
                                                }
                                                <th>{formatPrice(total)}</th>
                                              </tr>
                                            );
                                          }
                                        )}
                                        <tr>
                                        <th colSpan="7"></th>
                                        <th>Branch Total</th>
                                        <th>{formatPrice(data)}</th>
                                        </tr>
                                      </div>
                                      );
                                  }
                                )}
                                <tr></tr>
                                <tr>
                                <th colSpan="7"></th>
                                <th>Grand Total</th>
                                <th>{formatPrice(P_total)}</th>
                                </tr>
                              </tbody>
                              <tfoot style={{ border: "0px" }}>
                                <tr style={{ border: "0px" }}>
                                  <td
                                    colspan="7"
                                    className="text-center"
                                    style={{ border: "0px" }}
                                  >
                                    <h5 className="mt-3">
                                      Powered by indolj.pk
                                    </h5>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* thermal print */}
      <div className="d-none">
      <div ref={componentRefThermal}>
        {          
            <div className="fk-print">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                  <span className="mt-2 d-block fk-print-text_pos font-weight-bold text-uppercase text-center table-text">
                    Hourly Sales Report
                  </span>
                   {
                    newHourlySalesReport && newHourlySalesReport?.branch && (
                      <span className="mt-1 d-block fk-print-text_pos font-weight-bold text-uppercase text-center table-text">
                        {newHourlySalesReport.branch?.name}
                      </span>
                    )
                   }
                  <div>
                  <span className="mt-1 d-block fk-print-text_pos font-weight-bold text-uppercase text-center table-text">
                   FROM&nbsp;
                   <Moment format="LL">
                      {startDate}
                    </Moment>
                    &nbsp;TO&nbsp;
                    <Moment format="LL">{endDate}</Moment>
                  </span>
                  </div>
                  <div>
                  <span className="mt-1 d-block fk-print-text_pos font-weight-bold text-uppercase text-center table-text">
                    Status: {newHourlySalesReport?.type?.name ? newHourlySalesReport?.type?.name : "All"}                    
                  </span>
                  </div>
                  <div>
                  <span className="mt-1 mb-1 d-block fk-print-text_pos font-weight-bold text-uppercase text-center table-text">
                    Party: {newHourlySalesReport?.partyArray?.length > 0 ? newHourlySalesReport?.partyArray.map((item)=> item.name + ", ") : "All"}
                  </span>
               </div>
            {/*Transaction of party wise report */}
            <div className="col-12">
             {
              thermalTransaction &&
              thermalTransaction !== null && (
               <div>
                <p className="mb-0 print-text fk-print-text_pos text-capitalize d-flex justify-content-between border border-dark mr-1 font-weight-bold border-1">
                  <span className="font-weight-bold mx-2" style={{width:"28.5%"}}>
                  {thermalTransaction &&
                    thermalTransaction !== null &&
                    "Time"}
                    </span>
                    
                    <span className="font-weight-bold mx-2"  style={{width:"22.5%"}}>
                    {thermalTransaction &&
                      thermalTransaction !== null &&
                      "No Of Transaction"}
                    </span>
      
                    <span className="font-weight-bold mx-2" style={{width:"21%"}}>
                   
                    {thermalTransaction &&
                      thermalTransaction !== null &&
                      "Total Amount"}
                    </span>                          
                  </p>
                </div>
                )}
                <div className="w-100 d-flex mt-2">
                {
                  thermalHead  && (
                    <div className="border-dark border-left border-right border-top" style={{width:"41%"}}>
                       {
                        Object.keys(thermalHead).map((report,index,arr)=>{  
                          if(index !== arr.length-1){
                            return(                            
                              <div key={index} className={`mb-0 fk-print-text_pos print-text  text-capitalize mt-1 d-flex flex-column`}>
                                <span className="mx-2" >{report + "-" + arr[index+1]}</span>                                                      
                              </div>                                                      
                            )
                          }else{
                            return(                            
                              <div key={index} className={`mb-0 fk-print-text_pos print-text  text-capitalize mt-1 d-flex flex-column`}>
                                <span className="mx-2" >{report + "-" + arr[0]}</span>                                                      
                              </div>                                                      
                            )
                          }                     
                        })
                       }
                       <div className={`mb-0 fk-print-text_pos print-text  text-capitalize mt-1 border-dark border-top border-bottom d-flex flex-column`}>
                          <span className="mx-2" >Total</span>                                                      
                        </div>                   
                    </div>
                  ) 
                }
                {
                  thermalTransaction && (                                           
                    Object.keys(thermalTransaction).map((branch, index, arr) => {
                      let orders=0;
                      let total_amount=0;
                      return (
                        <>
                          <div className="border-dark border-top" style={{ width: "34%" }}>
                            {Object.values(thermalTransaction[branch]).map((item, index) => {
                              orders += item;
                              return (
                                <div key={index} className={`mb-0 fk-print-text_pos print-text  text-capitalize mt-1 d-flex flex-column`}>
                                  <span className="mx-2" >{item.toFixed(2)}</span>
                                </div>
                              )
                            })}
                            <div key={index} className={`mb-0 fk-print-text_pos print-text  text-capitalize mt-1 border-dark border-top border-bottom d-flex flex-column`}>
                              <span className="mx-2" >{orders}</span>
                            </div>
                          </div>
                          <div className="border-dark border-left border-right border-top" style={{ width: "24%" }}>
                            {thermalSales && Object.values(thermalSales[branch]).map((item, index) => {
                              total_amount += item;
                              return (
                                <div key={index} className={`mb-0 fk-print-text_pos print-text  text-capitalize mt-1 d-flex flex-column`}>
                                  <span className="mx-2" >{item.toFixed(2)}</span>
                                </div>
                              )
                            })}
                            <div key={index} className={`mb-0 fk-print-text_pos print-text  text-capitalize mt-1 border-dark border-top border-bottom  d-flex flex-column`}>
                              <span className="mx-2" >{total_amount.toFixed(2)}</span>
                            </div>
                          </div>
                        </>
                      )
                    })                                                            
                  )
                }     
                </div>          
              </div>
            
             </div> 
            </div> 
           </div> 
          </div>           
        }
      </div> 
    </div> 

      {/* Print Button */}
      {/*<div className="d-none">
        <div>
         
          <div className="fk-print">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {DeliveryData !== null &&
                    DeliveryData !== undefined &&
                    generatedReport === true && (
                      <>
                        <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                          <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                            <table
                              className="table table-bordered table-hover min-table-height mt-3"
                              id="table-to-xls"
                            >
                              <div className="page-break"></div>
                              <thead className="align-middle">
                                <tr style={{ border: "0px" }}>
                                  <th colspan="13" style={{ border: "0px" }}>
                                    <div className="row">
                                      <div className="col-12">
                                        <h3 className="text-center mb-0">
                                          {siteName}
                                        </h3>
                                        <h3 className="text-center mt-0">
                                          Hourly Sales Report
                                        </h3>
                                        <p className="text-center">
                                          FROM&nbsp;
                                          <Moment format="LL">
                                            {startDate}
                                          </Moment>
                                        </p>
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                                <tr>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  ></th>
                                  <th
                                    scope="col"
                                    colspan="3"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Dine In"))}
                                  </th>
                                  <th
                                    scope="col"
                                    colspan="3"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Delivery"))}
                                  </th>
                                  <th
                                    scope="col"
                                    colspan="3"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Takeaway"))}
                                  </th>
                                  <th
                                    scope="col"
                                    colspan="3"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Grand Total "))}
                                  </th>
                                </tr>
                                <tr>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Time Period"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Gross Sales"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Net Sales"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Checks"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Gross Sales"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Net Sales"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Checks"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Gross Sales"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Net Sales"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Checks"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Gross Sales"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Net Sales"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Checks"))}
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="align-middle">
                                {[...Array(24)].map((item, i) => {
                                  {
                                    dineingross += parseFloat(
                                      dineInData[i].gross_sales
                                    );
                                    dineinsales += parseFloat(
                                      dineInData[i].net_sales
                                    );
                                    dineinchecks += parseFloat(
                                      dineInData[i].check
                                    );
                                    deliverygross += parseFloat(
                                      DeliveryData[i].gross_sales
                                    );
                                    deliverysales += parseFloat(
                                      DeliveryData[i].net_sales
                                    );
                                    deliverychecks += parseFloat(
                                      DeliveryData[i].check
                                    );
                                    takeawaygross += parseFloat(
                                      takeawayData[i].gross_sales
                                    );
                                    takeawaysales += parseFloat(
                                      takeawayData[i].net_sales
                                    );
                                    takeawaychecks += parseFloat(
                                      takeawayData[i].check
                                    );
                                    grandgross += parseFloat(
                                      grandTotalData[i].gross_sales
                                    );
                                    grandsales += parseFloat(
                                      grandTotalData[i].net_sales
                                    );
                                    grandchecks += parseFloat(
                                      grandTotalData[i].check
                                    );
                                  }
                                  return (
                                    <tr>
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {dineInData[i].hours}
                                      </td>
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {dineInData[i].gross_sales}
                                      </td>
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {dineInData[i].net_sales}
                                      </td>
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {dineInData[i].check}
                                      </td>
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {DeliveryData[i].gross_sales}
                                      </td>
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {DeliveryData[i].net_sales}
                                      </td>
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {DeliveryData[i].check}
                                      </td>
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {takeawayData[i].gross_sales}
                                      </td>
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {takeawayData[i].net_sales}
                                      </td>
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {takeawayData[i].check}
                                      </td>
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {grandTotalData[i].gross_sales}
                                      </td>
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {grandTotalData[i].net_sales}
                                      </td>
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {grandTotalData[i].check}
                                      </td>
                                    </tr>
                                  );
                                })}
                                <tr>
                                  <th className="sm-text text-capitalize align-middle text-center border-1 border">
                                    Total
                                  </th>
                                  <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                    {formatPrice(dineingross)}
                                  </td>
                                  <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                    {formatPrice(dineinsales)}
                                  </td>
                                  <td> {formatPrice(dineinchecks)}</td>
                                  <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                    {formatPrice(deliverygross)}
                                  </td>
                                  <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                    {formatPrice(deliverysales)}
                                  </td>
                                  <td> {formatPrice(deliverychecks)}</td>
                                  <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                    {formatPrice(takeawaygross)}
                                  </td>
                                  <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                    {formatPrice(takeawaysales)}
                                  </td>
                                  <td> {formatPrice(takeawaychecks)}</td>
                                  <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                    {formatPrice(grandgross)}
                                  </td>
                                  <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                    {formatPrice(grandsales)}
                                  </td>
                                  <td> {formatPrice(grandchecks)}</td>
                                </tr>
                              </tbody>
                              <tfoot style={{ border: "0px" }}>
                                <tr style={{ border: "0px" }}>
                                  <td
                                    colspan="7"
                                    className="text-center"
                                    style={{ border: "0px" }}
                                  >
                                    <h5 className="mt-3">
                                      Powered by indolj.pk
                                    </h5>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>*/}

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ReportSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div
                  className="fk-scroll--pos-menu table-bottom-info-hide"
                  data-simplebar
                >
                  <div className="t-pl-15 t-pr-15">
                    {/* next page data spin loading */}
                    <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                      <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    {/* spin loading ends */}

                    {/* Loading effect */}
                    {/* {loading === true ? (
                      tableLoading()
                    ) : ( */}
                      <div key="smtp-form">
                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-12 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {_t(t("Hourly Sales Report"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="row gx-2 mt-2">
                          <div className="col-md-2 d-md-block">
                            <DatePicker
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              className="form-control sm-text py-2 t-mb-15 mb-md-0"
                              placeholderText={_t(t("from date"))}
                              shouldCloseOnSelect={false}
                            />
                          </div>
                          <div className="col-md-2 d-md-block">
                          <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            className="form-control sm-text py-2 t-mb-15 mb-md-0"
                            placeholderText={_t(t("to date"))}
                            shouldCloseOnSelect={false}
                            />
                          </div>
                          <div className="col-md-3 t-mb-15 mb-md-0">
                          <Select
                            value={newHourlySalesReport?.partyArray}
                            options={partyForSearch ? partyForSearch : []}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.name}
                            className="basic-multi-select sm-text"
                            classNamePrefix="select"
                            isMulti={true}
                            closeMenuOnSelect={false}
                            maxMenuHeight="200px"
                            onChange={handleSetParty}
                            placeholder={_t(t("Please select party")) + ".."}
                          />
                          </div> 
                          <div className="col-md-2 t-mb-15 mb-md-0">
                          <Select
                            options={reportType && reportType}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.name}
                            //value={branch}
                            classNamePrefix="select"
                            className="sm-text "
                            onChange={handleType}
                            maxMenuHeight="200px"
                            placeholder={_t(t("Select type")) + ".."}
                          />
                        </div>
                          <div className="col-md-3 t-mb-15 mb-md-0">
                            <Select
                              options={branchSearch ? branchSearch : []}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.name}
                              //value={branch}
                              classNamePrefix="select"
                              className="sm-text "
                              onChange={handleBranch}
                              maxMenuHeight="200px"
                              placeholder={_t(t("Select a branch")) + ".."}
                            />
                          </div>                                                                            
                        </div>
                        <div className="row justify-content-end mt-3">
                          {/* Action Button */}
                          <div className="col-2 t-mb-15 mb-md-0 d-none d-md-block text-right">
                          <button
                            className="btn btn-block btn-primary text-uppercase sm-text py-2 rounded"
                            onClick={(e) => getBranchWiseSelected(e)}
                          >
                            Party Wise Report
                          </button>
                         </div>
                         {/* Action Button */}
                         <div className="col-2 t-mb-15 mb-md-0 d-md-block text-right pl-0">
                         <button
                           className="btn btn-block btn-primary text-uppercase sm-text py-2 rounded"
                           onClick={(e) => getHourlyReport(e)}
                          >
                            Hourly Sales Report
                          </button>
                         </div>

                         <div className="col-2 t-mb-15 mb-md-0 d-md-block text-right pl-0">
                         <button
                           className="btn btn-block btn-primary text-uppercase sm-text py-2 rounded"
                           onClick={(e) => getThermalReport(e)}
                          >
                            Thermal Print Report
                          </button>
                         </div>
                        </div>
                        {
                          /*
                        <div className="row my-2">
                        <div className="col-10"></div>
                         <div className="col-2 t-mb-15 mb-md-0 d-md-block text-right pl-0">
                            <button
                              className="btn btn-block btn-primary text-uppercase sm-text py-2 rounded"
                              onClick={(e) => getBranchWiseSelected(e)}
                            >
                              Hourly Sales Report
                            </button>
                          </div>
                        </div> 
                         */
                      } 
                      </div>
                    {/* )} */}
                  </div>
                </div>
              </div>
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default HourlySalesReport;
