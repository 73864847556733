import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  tableLoading,  
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//pages & includes
import ManageSidebar from "../ManageSidebar";

//context consumer
import { RestaurantContext } from "../../../../../contexts/Restaurant";
import { UserContext } from "../../../../../contexts/User";
import { FoodContext } from "../../../../../contexts/Food";
const FinishGoodTransferAdd = () => {
    const { t } = useTranslation();
    const history = useHistory();
    //getting context values here
    let {
      loading,
      setLoading,
      branchForSearch,
    } = useContext(RestaurantContext);

    let { foodForSearch } = useContext(FoodContext)
  
    let {
      //finishGoodsTransfer
      setFinishGoodsTransferList,
      setFinishGoodsTransferListForSearch,
    } = useContext(UserContext);

    // States hook here
    //new item
    let [finishGoodsTransfer, setFinishGoodsTransfer] = useState({
      document_no: "",
      fromBranch: null,
      toBranch: null,
      remarks: "",
    });
    //new Row
    const [newRow,setNewRow]=useState([]);

    let common_toast = {
      position: "bottom-center",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      className: "text-center toast-notification",
    }
  
    //select style
    const customStyle = {
      control: (provided) => ({
        ...provided,
        minHeight: "2rem",
        marginTop: "0",
        marginBottom: "0",
      }),
  
      singleValue: (provided) => ({
        ...provided,
        alignSelf: "center",
      }),
      input: (provided) => ({
        ...provided,
        margin: "0",
      }),
      valueContainer: (provided) => ({
        ...provided,
        marginTop: "0",
        padding: "0 0.5rem",
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        padding: "0 0.4rem",
        svg: {
          height: "2rem",
        },
      }),
    };
  
    //datepicker
    const [documentDate, setDocumentDate] = useState(new Date());

    //set items hook
    const handleSetItems = async (index,item) => {
      newRow[index].transfer_qty="";
      newRow[index].item_name=item;
      setNewRow([...newRow]);
      getStock(index,item.id);
    };

    const getStock = async (index,id)=>{
      const url =
    BASE_URL +
      "/settings/get-finished-good-stock/" + id + "/branch/" + finishGoodsTransfer?.fromBranch?.id
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
          if(res){
            newRow[index].stock = res.data;
            setNewRow([...newRow]);
          }          
        } catch {
          toast.error(`${_t(t("Please try again"))}`, common_toast);
        }
    }
  
    const [rowId,setRowId]=useState(0);
    const addNewRow=()=>{
      let {fromBranch,toBranch} = finishGoodsTransfer;
      if(fromBranch !== null && toBranch !== null){
        setNewRow((pre)=>[...pre,{
          id:rowId,
          item_name:"",
          stock:"",
          transfer_qty:"",
        }])
        setRowId(rowId+1);
      }else{
        toast.error(`${_t(t("Please select both branch"))}`, common_toast);
      }   
    }
  
    const delRow=(id)=>{
      let row=[...newRow]
      row= row.filter((item,i)=>id!==item.id);
      setNewRow(row);
    }
  
    //set each item qty
    const handleStockQty = (index,e) => {
      newRow[index].stock = e.target.value;
      setNewRow([...newRow]);
    };

    const handleTransferQty = (index,e) => {
        if(+(e.target.value) <= +(newRow[index].stock)){
            newRow[index].transfer_qty = e.target.value;
            setNewRow([...newRow]);
        }else{
            toast.error(`${_t(t("Transfer Quantity must be less or equal to stock"))}`, common_toast);
        }
    };
  
    //handle Set branch hook
    const handleSetFromBranch = (fromBranch) => {
        setFinishGoodsTransfer({ ...finishGoodsTransfer, fromBranch, toBranch: null });
        setNewRow([]);
    };

    const handleSetToBranch = (toBranch) => {
        setFinishGoodsTransfer({ ...finishGoodsTransfer, toBranch });
    };

    const handleRemarks=(e)=>{
        setFinishGoodsTransfer({ ...finishGoodsTransfer, remarks:e.target.value });
    }
  
    //post req of stock item add
    const finishedGoodsAxios = async () => {
      let date =
        new Date(documentDate).getFullYear() +
        "-" +
        (new Date(documentDate).getMonth() + 1) +
        "-" +
        new Date(documentDate).getDate();
  
      let slugArray=[];
      let isDuplicate=false;
      newRow.map((newStockItem,index) => {
        if(newStockItem.item_name === ""){
          slugArray.push();
        }
        else{
          isDuplicate = slugArray.some((item) => item.item_id === newStockItem.item_name.id);
          slugArray.push({            
            item_id: newStockItem.item_name.id,
            item_name: newStockItem.item_name.name,
            stock_qty: newStockItem.stock,
            qty: newStockItem.transfer_qty            
          });
        }
        });
        
        if(newRow.length === slugArray.length){
          if(isDuplicate){
            toast.error(`${_t(t("Can't select same item"))}`, common_toast);
          }else{
          setLoading(true);
          let formData = {
            from_branch_id: finishGoodsTransfer.fromBranch.id,
            to_branch_id: finishGoodsTransfer.toBranch.id,    
            document_date: date,
            remarks: finishGoodsTransfer.remarks,
            items: slugArray,            
          };
  
      const url = BASE_URL + "/settings/new-finished-good-transfer";
      
      try {
          const res = await axios.post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });        
        setFinishGoodsTransferList(res.data[0]);
        setFinishGoodsTransferListForSearch(res.data[1]);
        setLoading(false);
        toast.success(`${_t(t("Goods has been added"))}`,common_toast);
      history.push(
        "/dashboard/manage/stock/finished-goods-transfer"
      )
      } catch (error) {        
        console.log("check error==>",error);
        setLoading(false);
        toast.error(`${_t(t("Please try again later"))}`, common_toast);
      }}
        }else{
          toast.error(`${_t(t("Please select item name"))}`, common_toast);
        }
  };
  
    //send to server
    const handleSubmit = (e) => {
      e.preventDefault();
      if (
        finishGoodsTransfer.fromBranch !== null &&
        finishGoodsTransfer.toBranch !== null &&
        documentDate !== null
      ) {
        if(newRow.length > 0){
            finishedGoodsAxios();
        }
        else{
          toast.error(`${_t(t("Please add goods"))}`, common_toast);
        }
      } else {
        toast.error(`${_t(t("Please fill all the required fields"))}`,common_toast);
      }
    };

    return (
      <>
        <Helmet>
          <title>{_t(t("Add Finished Goods Transfer"))}</title>
        </Helmet>
  
        {/* main body */}
        <main id="main" data-simplebar>
          <div className="container-fluid">
            <div className="row t-mt-10 gx-2">
              {/* left Sidebar */}
              <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
                <ManageSidebar />
              </div>
              {/* left Sidebar ends */}
  
              {/* Rightbar contents */}
              <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
                <div className="t-bg-white">
                  <div
                    className="fk-scroll--pos-menu table-bottom-info-hide"
                    data-simplebar
                  >
                    <div className="t-pl-15 t-pr-15">
  
                      {/* Loading effect */}
                      {loading === true ? (
                        tableLoading()
                      ) : (
                        <div key="smtp-form">
                          <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                            <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                              <ul className="t-list fk-breadcrumb">
                                <li className="fk-breadcrumb__list">
                                  <span className="t-link fk-breadcrumb__link text-capitalize">
                                    {_t(t("Add Finished Goods Transfer"))}
                                  </span>
                                </li>
                              </ul>
                            </div>
                            <div className="col-md-6 col-lg-7">
                              <div className="row gx-3 align-items-center"></div>
                            </div>
                          </div>
  
                          {/* Form starts here */}
                          <form
                            className="row card p-2 mx-3 mb-5 table-text"
                            onSubmit={handleSubmit}
                          >
                            <div className="col-12">
                            <div className="row">
                                <div className="form-group col-4 mt-3">
                                  <div className="mb-2">
                                    <label
                                      htmlFor="document no"
                                      className="control-label"
                                    >
                                      {_t(t("Document no"))}
                                      <span className="text-danger">*</span>
                                    </label>
                                  </div>
                                  <div className="mb-2">
                                    <input
                                      readOnly
                                      type="text"
                                      className="form-control table-text"
                                      id="document_no"
                                      name="document_no"                                      
                                      placeholder="Auto no"
                                      required
                                    />
                                  </div>
                                </div>
  
                                <div className="form-group col-4 text-right mt-3">
                                  <div className="mb-2 text-left">
                                    <label
                                      htmlFor="transfer_date"
                                      className="control-label"
                                    >
                                      {_t(t("Document Date"))}
                                      <span className="text-danger">*</span>
                                    </label>
                                  </div>
                                  <div className="mb-2">
                                    <DatePicker
                                      selected={documentDate}
                                      onChange={(date) => setDocumentDate(date)}
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      className="form-control table-text"
                                      placeholderText={_t(t("Document date"))}
                                      shouldCloseOnSelect={false}
                                      required
                                    />
                                  </div>
                                </div>
                                
                                <div className="form-group mt-3 col-4">
                                    <div className="mb-2">
                                      <label
                                        htmlFor="from-branch"
                                        className="control-label"
                                      >
                                        {_t(t("From Branch"))}
                                        <span className="text-danger">*</span>                                        
                                      </label>
                                    </div>
                                    <Select
                                      options={branchForSearch ? branchForSearch : []}
                                      components={makeAnimated()}
                                      getOptionLabel={(option) => option.name}
                                      getOptionValue={(option) => option.name}
                                      value={finishGoodsTransfer.fromBranch || null}
                                      classNamePrefix="select"
                                      onChange={handleSetFromBranch}
                                      maxMenuHeight="200px"
                                      styles={customStyle}
                                      placeholder={
                                        _t(t("Please select a branch")) + ".."
                                      }
                                    />
                                </div> 
                              </div>
  
                              <div className="row">
                              <div className="form-group mt-2 col-4">
                                    <div className="mb-2">
                                      <label
                                        htmlFor="to-branch"
                                        className="control-label"
                                      >
                                        {_t(t("To Branch"))}
                                        <span className="text-danger">*</span>                                        
                                      </label>
                                    </div>
                                    <Select
                                      options={branchForSearch?.filter((item)=>item.id !== finishGoodsTransfer.fromBranch?.id)}
                                      components={makeAnimated()}
                                      getOptionLabel={(option) => option.name}
                                      getOptionValue={(option) => option.name}
                                      value={finishGoodsTransfer.toBranch || null}
                                      classNamePrefix="select"
                                      onChange={handleSetToBranch}
                                      maxMenuHeight="200px"
                                      styles={customStyle}
                                      isDisabled={finishGoodsTransfer.fromBranch === null && true}  
                                      placeholder={
                                        _t(t("Please select a branch")) + ".."
                                      }
                                    />
                                </div>                                                           
                                <div className="form-group mt-2 col-8">
                                  <div className="mb-2">
                                    <label
                                      htmlFor="remarks"
                                      className="control-label"
                                    >
                                      {_t(t("Remarks"))}
                                    </label>
                                  </div>
                                  <div className="mb-2">
                                    <textarea
                                      type="text"
                                      className="form-control table-text"
                                      id="remarks"
                                      name="remarks"
                                      onChange={handleRemarks}
                                      value={finishGoodsTransfer.remarks || ""}
                                      placeholder="Remarks"
                                      style={{height:"33px"}}
                                    />
                                  </div>
                                </div>
                              </div>                            
                              
                              <div className={`table-responsive mt-3 pr-1 `}
                              style={{maxHeight:"23rem",overflow: "auto"}}>
                                  <table className="table table-bordered-sm table-hover">
                                    <thead className="align-middle">                                    
                                      <tr>
                                      {newRow.length==0 ?<th
                                        scope="col"
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                      >
                                          <span className="t-mr-10" onClick={()=>addNewRow()}>
                                              <i className="fa fa-plus-circle cursor-pointer btn-add-icon" style={{fontSize:"22px"}} aria-hidden="true"></i>
                                          </span>
                                      </th>:<th style={{borderBottomColor:"lightgray"}}></th>}
                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                          style={{width:"35rem"}}
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Item Name"))}
                                          </span>
                                        </th>                                     
                                        
                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Stock"))}
                                          </span>
                                        </th>

                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Transfer Qty"))}
                                          </span>
                                        </th>
                                        
                                        {newRow.length>0 && <th style={{borderBottomColor:"lightgray",minWidth:"60px"}}></th>}
                                      </tr>
                                    </thead>
                                      {newRow && newRow.length>0 ?
                                    <tbody className="align-middle">
                                      {newRow.map(
                                        (stockItem, index) => {
                                          return (
                                            <tr
                                              className="align-middle"
                                              key={index}
                                            >
                                              <td className="table-text text-capitalize align-middle text-center table-firstChild" style={{minWidth:"60px"}}>
                                          {index==(newRow.length-1) && <span className="t-mr-10" onClick={()=>addNewRow()}>
                                              <i className="fa fa-plus-circle btn-add-icon" style={{fontSize:"22px"}} aria-hidden="true"></i>
                                          </span>}&nbsp;
                                          <i className="fa fa-minus-circle cursor-pointer text-danger" style={{fontSize:"22px"}} 
                                          onClick={()=>{delRow(stockItem.id)}}
                                          ></i>
                                          </td>
                                              <td className="table-text text-capitalize align-middle text-center">
                                              <Select
                                                options={foodForSearch !== null ? foodForSearch : []}
                                                components={makeAnimated()}
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.id}
                                                classNamePrefix="select"
                                                onChange={(e)=>handleSetItems(index,e)}
                                                // maxMenuHeight="12.8rem"                                                
                                                value={stockItem.item_name}
                                                styles={customStyle}
                                                isMenuOpen={true}
                                                menuPosition="fixed"
                                                className="bank-table-input"                                              
                                                placeholder={
                                                _t(t("Please select items")) + ".."
                                                }                               
                                              />
                                              </td>                                      

                                              <td className="table-text text-capitalize align-middle text-center">
                                                <input
                                                  readOnly
                                                  type="number"
                                                  step="0.1"
                                                  min="0"
                                                  onKeyDown={(evt) =>
                                                    ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                                    evt.preventDefault()
                                                  }
                                                  onWheel={(e) => e.target.blur()}
                                                  className="form-control text-center table-Input table-text"
                                                  id={stockItem.id}
                                                  name={stockItem.id}
                                                  onChange={(e)=>handleStockQty(index,e)}
                                                  value={stockItem.stock}
                                                  placeholder="Stock"
                                                  required                                                  
                                                />
                                              </td>

                                              <td className="table-text text-capitalize align-middle text-center">                                                
                                                <input
                                                  type="number"
                                                  step="0.1"
                                                  min="0"
                                                  onKeyDown={(evt) =>
                                                    ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                                    evt.preventDefault()
                                                  }
                                                  onWheel={(e) => e.target.blur()}
                                                  className="form-control text-center table-Input table-text"
                                                  id={stockItem.id}
                                                  name={stockItem.id}
                                                  onChange={(e)=>handleTransferQty(index,e)}
                                                  value={stockItem.transfer_qty || ""}
                                                  placeholder="transfer qty"
                                                  required                                                  
                                                />
                                              </td>                                              
                                            
                                              <td className="table-text text-capitalize align-middle text-center" style={{minWidth:"60px"}}>
                                                <i className="fa fa-minus-circle cursor-pointer text-danger" style={{fontSize:"22px"}} onClick={()=>{delRow(stockItem.id)}}></i>&nbsp;
                                                {index==(newRow.length-1) && <span className="t-mr-10" onClick={()=>addNewRow()}>
                                                  <i className="fa fa-plus-circle cursor-pointer btn-add-icon" style={{fontSize:"22px"}} aria-hidden="true"></i>
                                                </span>}                                             
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </tbody>
                                    :null}                                    
                                  </table>
                              </div>
                                
  
                              <div className="form-group mt-4 pb-2">                            
                                <div className="d-flex justify-content-end align-items-center">
                            <div className="col-3 d-flex justify-content-end">
                                <button
                                type="button"
                                className="btn rounded hov-btn mr-3"
                                onClick={()=>(history.push("/dashboard/manage/stock/finished-goods-transfer"))}                                
                                >
                                    <i class="fa fa-arrow-left" aria-hidden="true"></i> {_t(t("Back"))}
                                </button>
                                <button
                                disabled={loading}
                                type="submit"
                                className="btn btn-primary rounded"
                                >
                                    <i class="fa fa-floppy-o" aria-hidden="true"></i> {_t(t("Save"))}
                                </button>                                                    
                            </div>                                                    
                        </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* Rightbar contents end*/}
            </div>
          </div>
        </main>
        {/* main body ends */}
      </>
    );
}

export default FinishGoodTransferAdd;