import React, { useEffect, useContext, useState,useRef } from "react";
import { useHistory } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  tableLoading,
  currencySymbolLeft,
  formatPrice,
  currencySymbolRight,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MdDelete } from 'react-icons/md';
import StockReport from "./StockReport";

//pages & includes
import ManageSidebar from "../ManageSidebar";

//context consumer
import { SettingsContext } from "../../../../../contexts/Settings";
import { RestaurantContext } from "../../../../../contexts/Restaurant";
import { UserContext } from "../../../../../contexts/User";
import { FoodContext } from "../../../../../contexts/Food"

const PurchaseOrder = () => {
    const { t } = useTranslation();
  const history = useHistory();
  //getting context values here
  let {
    loading,
    setLoading,
    branchForSearch,
    getIngredientItem,
    ingredientItemForSearch,
    setIngredientPurchaseHistory,
    setIngredientPurchaseHistoryForSearch,
    getWorkPeriod,
    dataPaginating,
  } = useContext(RestaurantContext);
  let { getSupplier, supplierForSearch } = useContext(UserContext);
  let { workPeriodWeb, getFoodWeb } = useContext(FoodContext);
  // States hook here
  //new item
  let [newItem, setNewItem] = useState({
    // branch: null,
    supplier: null,
    // invoice: "",
    description: "",
    // paymentType: "",
    // items: null,
    // paid: null,
    // total: null,
    // due: null,
  });

  //datepicker
  const [purchaseOrderDate, setpurchaseOrderDate] = useState(new Date());

  //qty and rate
  let [qtyOfItem, setQtyOfItem] = useState(null);
  let [rateOfItem, setRateOfItem] = useState(null);
   //print bills
   const componentRef = useRef();

  //useEffect == componentDidMount()
  useEffect(() => {
    getFoodWeb();
    getSupplier();
    getIngredientItem();
  }, []);

  const handleOnlyPrint = useReactToPrint({
    content: () => componentRef.current,
    
  });

  //select style
  const customStyle = {
      control: (provided) => ({
        ...provided,
        minHeight : '2rem',
        marginTop:"0",
        marginBottom:"0",
      
      }),
     
      singleValue: (provided) =>({
        ...provided,
        alignSelf: "center",
      }),
      input:(provided)=>({
        ...provided,
       margin:"0",
      }),
      valueContainer: (provided) => ({
        ...provided,
        marginTop:"0",
        padding:"0 0.5rem",
       
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        padding:"0 0.4rem",
        "svg": {
          height: '2rem',
        }
      }),
     
    }
  
  //on change input field
  const handleDesc = (e) => {
    setNewItem({ ...newItem, description: e.target.value });
  };

  //set items hook
  const handleSetItems = async (index,item) => {
    // let data = newItem?.items ? newItem.items : []
    // if(data.includes(item)){
    //   toast.error("Already exist please select another item")
    // }else{
    //   //newItem?.items && data.push(...newItem.items.reverse());
    //   data.unshift(item);
    //   setNewItem({
    //     ...newItem,
    //     items: data,
    //   });
    // }
    newRow[index].unit="";
      newRow[index].base_unit="";
      newRow[index].base_qty="";
      newRow[index].conversion_qty="";
      newRow[index].item_name=item;
      newRow[index].base_unit=item.unit_id;
      newRow[index].item_units=""
      setNewRow([...newRow]);
      const url = BASE_URL + "/settings/get-unit_item/"+item.id;
      await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
      .then((res) => {
        if(res.data){
        newRow[index].item_units = res.data;
        setNewRow([...newRow]);
        }      
      }).catch((error) =>{
        console.log("check error",error);
      })

  };

  //handle unit
  const handleSetUnit=(index,unit)=>{
    newRow[index].unit=unit;
    newRow[index].conversion_qty=parseFloat(unit.qty).toFixed(2);
    setNewRow([...newRow]);
  }


  //subtotal
  const handleGetSubTotal = (index) => {
    let qty = 0;
    let rate = 0;
    if (newRow !== null && newRow[index].qty) {
      qty = parseFloat(newRow[index].qty);
    }
    if (newRow !== null && newRow[index].rate) {
      rate = parseFloat(newRow[index].rate);
    }
    return rate * qty;
  };

   //subtotal
   const handleGetAmount = (id) => {
    let qty = 0;
    let rate = 0;
    if (qtyOfItem !== null && qtyOfItem[id]) {
      qty = parseFloat(qtyOfItem[id]);
    }
    if (rateOfItem !== null && rateOfItem[id]) {
      rate = parseFloat(rateOfItem[id]);
    }
    return rate * qty;
  };

    // total amount
    const handleAmount = () => {
      let total = 0;
      if (newItem.items !== null) {
        newItem.items.map((item) => {
          if (
            qtyOfItem &&
            rateOfItem &&
            qtyOfItem[item.id] &&
            rateOfItem[item.id]
          ) {
            total = total + qtyOfItem[item.id] * rateOfItem[item.id];
          }
        });
      }
      // setNewItem({
      //   ...newItem,
      //   item_amount:total
      // });
      return total;
    };

  //total
  const handleGetTotal = () => {
    let total = 0;
    if (newRow !== null) {
      newRow.map((item) => {
        if (item.rate && item.qty) {
          total = total + item.qty * item.rate;
        }
      });
    }
    return total;
  };

  //set each item qty
  const handleItemQty = (index,e) => {
    // setQtyOfItem({
    //   ...qtyOfItem,
    //   [e.target.name]: e.target.value,
    // });
    newRow[index].qty=e.target.value;
    setNewRow([...newRow]);
  };

  //set each item qty
  const handleItemRate = (index,e) => {
    // setRateOfItem({
    //   ...rateOfItem,
    //   [e.target.name]: e.target.value,
    // });
    newRow[index].rate=e.target.value;
    setNewRow([...newRow]);
  };


  //handle Set supplier hook
  const handleSetSupplier = (supplier) => {
    setNewItem({ ...newItem, supplier });
  };

  //post req of food item add
  const ingredientItemAxios = async () => {
    // setLoading(true);
    let date =
      new Date(purchaseOrderDate).getFullYear() +
      "-" +
      (new Date(purchaseOrderDate).getMonth() + 1) +
      "-" +
      new Date(purchaseOrderDate).getDate();
     

    // formData.append("item_amount", handleAmount());

    //converting items and prices to array
    let slugArray = [];
    newRow.map((newIngredientItem,index) => {
      slugArray.push({
        qty: newIngredientItem.qty,
        rate: newIngredientItem.rate,
        item_id: newIngredientItem.item_name.id,
        item_name: newIngredientItem.item_name.name,
        unit_name: newIngredientItem.unit.name,
        amount: handleGetSubTotal(index),
        base_unit: newIngredientItem.base_unit,
        base_qty: calBaseQty(index),
        conversion_qty: parseFloat(newIngredientItem.conversion_qty)
      });
    });

    let formData = {
      supplier_id : newItem.supplier.id,
      document_date : date,
      remarks : newItem.description,
      total_amount : handleGetTotal(),
      items : slugArray
    }
    console.log("check form data==>",formData);

    // const url = BASE_URL + "/settings/new-purchase-order";
    //  await axios
    //   .post(url, formData, {
    //     headers: { Authorization: `Bearer ${getCookie()}` },
    //   })
    // .then((res) => {
      
    //   // handleOnlyPrint();
    //   getIngredientItem();
    //   setIngredientPurchaseHistory(res.data[0])
    //   setIngredientPurchaseHistoryForSearch(res.data[1])
    //   setNewItem({
    //     branch: null,
    //     supplier: null,
    //     invoice: "",
    //     description: "",
    //     paymentType: "",
    //     items: null,
    //     paid: null,
    //     total: null,
    //     due: null,
    //   });
    //   setQtyOfItem(null);
    //   setRateOfItem(null);
    //   setLoading(false);
    //   toast.success(`${_t(t("Purchase Order has been added"))}`, {
    //     position: "bottom-center",
    //     autoClose: 10000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     className: "text-center toast-notification",
    //   });
    // }).catch((error) =>{
    //   setLoading(false);
    //   toast.error(`${_t(t("Please try again later"))}`, {
    //     position: "bottom-center",
    //     autoClose: 10000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     className: "text-center toast-notification",
    //   });
    // })
  };

  //send to server
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      //newItem.branch !== null &&
      newItem.supplier !== null &&
      newItem.purchaseOrderDate !== null
    ) {
      if(newRow.length>0){
        ingredientItemAxios();
      }else{
        toast.error(`${_t(t("Please add orders"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      }
    } else {
      toast.error(`${_t(t("Please fill all required fields"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }
  };

  const calBaseQty=(index)=>{
    let qty=newRow[index].qty;
    let conversion_qty=newRow[index].conversion_qty
    if(qty && conversion_qty){
      return qty * conversion_qty;
    }else{
      return "";
    }
  }

  //new Row
  const [newRow,setNewRow]=useState([]);
  const [rowId,setRowId]=useState(0);
  const addNewRow=()=>{
    // if(newOpeningStock.branch !== null){
      setNewRow((pre)=>[...pre,{
        id:rowId,
        item_name:"",
        qty:"",
        unit:"",
        item_units:"",
        rate:"",          
        // discount_percent:"",
        // tax_percent:"",
        base_unit:"",
        base_qty:"",
        conversion_qty:""
      }])
      setRowId(rowId+1);
    // }else{
    //   toast.error(`${_t(t("Please select branch"))}`, {
    //     position: "bottom-center",
    //     autoClose: 10000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     className: "text-center toast-notification",
    //   });
    // }   
  }

  const delRow=(id)=>{
    let row=[...newRow]
    row= row.filter((item,i)=>id!==item.id);
    setNewRow(row);
  }

  return (
    <>
    <Helmet>
      <title>{_t(t("Add Purchase Order"))}</title>
    </Helmet>

    {/* main body */}
    <main id="main" data-simplebar>
      <div className="container-fluid">
        <div className="row t-mt-10 gx-2">
          {/* left Sidebar */}
          <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
            <ManageSidebar />
          </div>
          {/* left Sidebar ends */}

          {/* Rightbar contents */}
          <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
            <div className="t-bg-white">
              <div
                className="fk-scroll--pos-menu table-bottom-info-hide"
                data-simplebar
              >
                <div className="t-pl-15 t-pr-15">
                  {/* next page data spin loading */}
                  <div className={`${dataPaginating && "loading"}`}></div>
                  {/* spin loading ends */}

                  {/* Loading effect */}
                  {loading === true ? (
                    tableLoading()
                  ) : (
                    <div key="smtp-form">
                      <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                        <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                          <ul className="t-list fk-breadcrumb">
                            <li className="fk-breadcrumb__list">
                              <span className="t-link fk-breadcrumb__link text-capitalize">
                                {_t(t("Add Purchase Order"))}
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-6 col-lg-7">
                          <div className="row gx-3 align-items-center"></div>
                        </div>
                      </div>

                      {/* Form starts here */}
                      <form
                        className="row card p-2 mx-3 mb-5 table-text"
                        onSubmit={handleSubmit}
                      >
                        <div className="col-12">
                          <div className="row">
                          {branchForSearch && (
                            <>                            
                            <div className="form-group col-4 mt-2">
                              <div className="mb-2">
                                <label
                                  htmlFor="invoice"
                                  className="control-label"
                                >
                                  {_t(t("PO #"))}
                                </label>
                              </div>
                              <div className="mb-2">
                                <input
                                  disabled={true}
                                  type="text"
                                  className="form-control table-text"
                                  id="invoice"
                                  name="invoice"
                                  // onChange={handleChange}
                                  // value={newItem.invoice}
                                  placeholder="Auto No"
                                />
                              </div>
                            </div>

                            <div className="form-group col-4 mt-2">
                              <div className="mb-2 ">
                                <label
                                  htmlFor="purchaseDate"
                                  className="control-label"
                                >
                                  {_t(t("PO Date"))}
                                  <span className="text-danger">*</span>
                                </label>
                              </div>
                              <div className="mb-2">
                                <DatePicker
                                  selected={purchaseOrderDate}
                                  onChange={(date) => setpurchaseOrderDate(date)}
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  className="form-control table-text"
                                  placeholderText={_t(t("Purchase date"))}
                                  shouldCloseOnSelect={false}
                                />
                              </div>
                            </div>

                            <div className="form-group mt-2 col-4">
                              <div className="mb-2 ">
                                <label
                                  htmlFor="supplier"
                                  className="control-label"
                                >
                                  {_t(t("Supplier/Vendor"))}
                                  <span className="text-danger">*</span>
                                </label>
                              </div>
                              <Select
                                options={supplierForSearch}
                                components={makeAnimated()}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                value={newItem.supplier || ""}
                                classNamePrefix="select table-text"
                                onChange={handleSetSupplier}
                                //maxMenuHeight="50px"
                                placeholder={
                                  _t(t("Please select a supplier")) + ".."
                                }
                                styles={customStyle}
                                required
                              />
                              </div>
                              </>
                                            
                          )}
                          
                        </div>
                         <div className="row">
                          <div className="form-group mt-2 col-12">
                          <div className="mb-2">
                            <label
                              htmlFor="description"
                              className="control-label"
                            >
                              {_t(t("Description"))}
                            </label>
                          </div>
                          <div className="mb-2">
                            <input
                              type="text"
                              className="form-control table-text"
                              id="description"
                              name="description"
                              onChange={handleDesc}
                              value={newItem.description}
                              placeholder="Short description"
                            />
                          </div>
                         </div>
                        </div>                          
                            <div className="table-responsive mt-3 pr-1" style={{maxHeight:"23rem",overflow: "auto"}}>
                                <table className="table table-bordered-sm table-hover">
                                  <thead className="align-middle">
                                    <tr>
                                    {newRow.length==0 ?<th
                                        scope="col"
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                      >
                                          <span className="t-mr-10" onClick={()=>addNewRow()}>
                                              <i className="fa fa-plus-circle cursor-pointer btn-add-icon" style={{fontSize:"22px"}} aria-hidden="true"></i>
                                          </span>
                                      </th>:<th style={{borderBottomColor:"lightgray"}}></th>}
                                      <th
                                        scope="col"
                                        className="table-text text-capitalize align-middle text-center border-1 border "
                                      >
                                        <span style={{fontWeight:500}}>
                                          {_t(t("Name"))}
                                        </span>
                                      </th>
                                    
                                      <th
                                        scope="col"
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                      >
                                        <span style={{fontWeight:500}}>
                                          {_t(t("Qty"))}
                                        </span>
                                      </th>

                                      <th
                                        scope="col"
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                      >
                                        <span style={{fontWeight:500}}>
                                          {_t(t("Unit"))}
                                        </span>
                                      </th>

                                      <th
                                        scope="col"
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                      >
                                        <span style={{fontWeight:500}}>
                                          {_t(t("Rate"))}
                                        </span>
                                      </th>

                                      <th
                                      scope="col"
                                      className="table-text text-capitalize align-middle text-center border-1 border"
                                     >
                                      <span style={{fontWeight:500}}>
                                        {_t(t("Amount"))}
                                      </span>
                                     </th>

                                     <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Base unit"))}
                                          </span>
                                        </th>
                                        
                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Base qty"))}
                                          </span>
                                        </th>
                                        
                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                          style={{minWidth:"9rem"}}
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Conversion unit"))}
                                          </span>
                                        </th>                                  
                                    {newRow.length>0 && <th style={{borderBottomColor:"lightgray",minWidth:"60px"}}></th>}
                                    </tr>
                                  </thead>
                                  {newRow && newRow.length>0 ?
                                  <tbody className="align-middle">
                                    {newRow.map(
                                      (ingredientItem, index) => {
                                        return (
                                          <tr
                                            className="align-middle"
                                            key={index}
                                          >
                                            <td className="table-text text-capitalize align-middle text-center table-firstChild" style={{minWidth:"60px"}}>
                                          {index==(newRow.length-1) && <span className="t-mr-10" onClick={()=>addNewRow()}>
                                              <i className="fa fa-plus-circle btn-add-icon" style={{fontSize:"22px"}} aria-hidden="true"></i>
                                          </span>}&nbsp;
                                          <i className="fa fa-minus-circle cursor-pointer text-danger" style={{fontSize:"22px"}} 
                                          onClick={()=>{delRow(ingredientItem.id)}}
                                          ></i>
                                          </td>
                                            <td className="table-text text-capitalize align-middle text-center table-Child">
                                            <Select
                                              options={ingredientItemForSearch}
                                              components={makeAnimated()}
                                              getOptionLabel={(option) => option.name}
                                              getOptionValue={(option) => option.id}
                                              classNamePrefix="select table-text"
                                              onChange={(e)=>handleSetItems(index,e)}
                                              maxMenuHeight="150px"                                
                                              placeholder={
                                              _t(t("Please select items")) + ".."
                                              }
                                              menuPosition="fixed"
                                              styles={customStyle}
                                            />
                                            </td>

                                            <td className="table-text text-capitalize align-middle text-center" style={{width:"15%"}}>
                                            <input
                                              type="number"
                                              step="0.1"
                                              min="0"
                                              onKeyDown={(evt) =>
                                                ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                                evt.preventDefault()
                                              }
                                              onWheel={(e) => e.target.blur()}
                                              className="form-control text-center table-Input table-text"
                                              id={ingredientItem.id}
                                              name={ingredientItem.id}
                                              onChange={(e)=>handleItemQty(index,e)}
                                              value={ingredientItem.qty}
                                              placeholder="Qty"
                                              required
                                            />
                                          </td>

                                            <td className="table-text text-capitalize align-middle text-center">
                                                <Select
                                                  menuPosition="fixed"
                                                  options={ingredientItem.item_units ? ingredientItem.item_units : []}
                                                  components={makeAnimated()}
                                                  getOptionLabel={(option) => option.name}
                                                  getOptionValue={(option) => option.id}
                                                  value={ingredientItem.unit}
                                                  classNamePrefix="select"
                                                  onChange={(e)=>handleSetUnit(index,e)}
                                                  className="bank-table-input"
                                                  maxMenuHeight="200px"
                                                  styles={customStyle}                                                  
                                                  placeholder={
                                                  _t(t("please select unit")) + ".."
                                                  } 
                                                  isDisabled={ingredientItem.item_units?false:true}                                                  
                                                />
                                            </td>
                                            <td className="table-text text-capitalize align-middle text-center" style={{width:"15%"}}>
                                              <input
                                                type="number"
                                                step="0.1"
                                                min="0"
                                                onKeyDown={(evt) =>
                                                  ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                                  evt.preventDefault()
                                                }
                                                onWheel={(e) => e.target.blur()}
                                                className="form-control text-center table-Input table-text"
                                                id={ingredientItem.id}
                                                name={ingredientItem.id}
                                                onChange={(e)=>handleItemRate(index,e)}
                                                value={ingredientItem.rate}
                                                placeholder="Price"
                                                required                                               
                                              />
                                            </td>

                                            <td className="table-text align-middle text-center">
                                              {formatPrice(
                                                handleGetSubTotal(
                                                  index
                                                )
                                              )}
                                             </td>

                                             <td className="table-text align-middle text-center">
                                                {ingredientItem.base_unit}
                                              </td> 
                                              
                                              <td className="table-text align-middle text-center">                                                
                                                {formatPrice(calBaseQty(index))}
                                              </td>
                                              
                                              <td className="table-text align-middle text-center">
                                                {ingredientItem.conversion_qty}
                                              </td> 

                                             <td className="table-text text-capitalize align-middle text-center" style={{minWidth:"60px"}}>
                                                <i className="fa fa-minus-circle cursor-pointer text-danger" style={{fontSize:"22px"}} onClick={()=>{delRow(ingredientItem.id)}}></i>&nbsp;
                                                {index==(newRow.length-1) && <span className="t-mr-10" onClick={()=>addNewRow()}>
                                                  <i className="fa fa-plus-circle cursor-pointer btn-add-icon" style={{fontSize:"22px"}} aria-hidden="true"></i>
                                                </span>}                                             
                                              </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                  </tbody>
                                  : null }
                                  {newRow && newRow.length>0 ?
                                  <tfoot className="align-middle">
                                    <tr>
                                      <th
                                      style={{minWidth:"100px"}}
                                        scope="col"
                                        colSpan="4"
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                      ></th>

                                      <th
                                      style={{minWidth:"100px"}}
                                        scope="col"
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                      >
                                        <span style={{fontWeight:500}}>
                                          {_t(t("Total Amount"))}
                                        </span>
                                      </th>

                                      <th
                                      style={{minWidth:"100px"}}
                                        scope="col"                                        
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                      >
                                        {formatPrice(handleGetTotal())}
                                      </th>
                                      <th
                                      style={{minWidth:"100px"}}
                                        scope="col"
                                        colSpan="4"
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                      >                                        
                                      </th>
                                    </tr>
                                   
                                  </tfoot>
                                  : null }
                                </table>
                            </div>                            
                          <div className="form-group mt-4 pb-2">
                            {/* <div className="col-lg-12">
                              <button
                                className="btn btn-primary px-5 rounded"
                                type="submit"
                              >
                                {_t(t("Save"))}
                              </button>
                              <button
                                className="btn btn-primary px-5 mx-2 rounded"
                                onClick={()=>(history.push("/dashboard/manage/stock/purchase-order"))}
                              >
                                {_t(t("Back"))}
                              </button>
                            </div> */}
                            <div className="d-flex justify-content-end align-items-center">
                            <div className="col-3 d-flex justify-content-end">
                                <button
                                type="button"
                                className="btn rounded hov-btn mr-3"
                                onClick={()=>(history.push("/dashboard/manage/stock/purchase-order"))}
                                //   data-dismiss="modal"
                                >
                                    <i class="fa fa-arrow-left" aria-hidden="true"></i> {_t(t("Back"))}
                                </button>
                                <button
                                disabled={loading}
                                type="submit"
                                className="btn btn-primary rounded"
                                >
                                    <i class="fa fa-floppy-o" aria-hidden="true"></i> {_t(t("Save"))}
                                </button>                                                    
                            </div>                                                    
                        </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* Rightbar contents end*/}
        </div>
      </div>
    </main>
      {/* main body ends */}
      {/* <StockReport
      name = "Purchase Order"
      purchaseDate={purchaseOrderDate}
      newItem={newItem}
      qtyOfItem={qtyOfItem}
      rateOfItem={rateOfItem}
      handleGetTotal={handleGetTotal}
      handleAmount={handleAmount}
      handleTaxAmount={0}
      handleDisAmount={0}
      handleGetSubTotal={handleGetSubTotal}
      handleGetAmount={handleGetAmount}
      stockPrint={componentRef}
      /> */}
  </>
  )
}

export default PurchaseOrder
