import React, { useEffect, useContext, useState,useRef } from "react";
import { useHistory, useParams } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  tableLoading,
  formatPrice,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//pages & includes
import AccountsSidebar from "../AccountsSidebar";

//context consumer

import { UserContext } from "../../../../../contexts/User";
import { FinanceContext } from "../../../../../contexts/Finance";
const EditJournalVoucher = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const params=useParams();
    //getting context values here
    let { getSupplier, supplierForSearch } = useContext(UserContext);
    // let { workPeriodWeb, getFoodWeb } = useContext(FoodContext);
    //finance context
    const {
      loading,
      setLoading,

        //coa3        
        coa3ForSearch,

        //JVList
        setJournalVoucherList,
        setJournalVoucherListForSearch,      

        //pagination
        dataPaginating,
    } = useContext(FinanceContext);

    //Add new row
    const [newRow,setNewRow]=useState([]);

    //bankpayment state
    const [journalVoucher,setJournalVoucher]=useState({
      doc_no:"",
      doc_date:new Date(),
      remarks:""
    });

    //edit account
    const [editAccount,setEditAccount]=useState(null);

      let rowId = useRef(0); 

     const getSpecificAccount = async () => {
        setLoading(true);
        const url =
          BASE_URL +
          "/settings/show-journal-voucher/" +
          parseInt(params.id);
        try {
          const res = await axios
            .get(url, {
              headers: { Authorization: `Bearer ${getCookie()}` },
            });
            if(res){
              setEditAccount(res.data)
            }            
    
          setLoading(false);
        } catch {
          setLoading(false);
        }
      };
  
    //useEffect == componentDidMount()
    useEffect(() => {
      getSupplier();
      getSpecificAccount();
    }, []);
    useEffect(() => {
        if(editAccount && editAccount !== null){
          let docDate=new Date(editAccount.journalVoucher.document_date);          
          setJournalVoucher({
            ...journalVoucher,
            doc_no:editAccount.journalVoucher.document_identity,
            doc_date:docDate,
            remarks:editAccount.journalVoucher.remarks,
          })
          let rowArr=[]
          if(editAccount.journalVoucherDetail && editAccount.journalVoucherDetail.length > 0){
            editAccount.journalVoucherDetail.map((item)=>{
              let acc_name=coa3ForSearch?.find((v,i)=>item.coa_id==v.id) 
              let suppAcc=supplierForSearch?.find((v)=>v.id === item.supplier_id)              
              rowArr.push({                
                id:rowId.current,
                account_name:acc_name,
                supplier:suppAcc,
                remarks:item.remarks,
                cheque_date:new Date(item.cheque_date),
                cheque_no:item.cheque_no,
                debit:parseFloat(item.debit).toFixed(2),
                credit:parseFloat(item.credit).toFixed(2)
              })
            })
            setNewRow([...rowArr]);
          }     
        }
      }, [editAccount,coa3ForSearch,supplierForSearch]);
  
    //select style
    const customStyle = {
      control: (provided) => ({
        ...provided,
        minHeight: "2rem",
        marginTop: "0",
        marginBottom: "0",
      }),
  
      singleValue: (provided) => ({
        ...provided,
        alignSelf: "center",
      }),
      input: (provided) => ({
        ...provided,
        margin: "0",
      }),
      valueContainer: (provided) => ({
        ...provided,
        marginTop: "0",
        padding: "0 0.5rem",
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        padding: "0 0.4rem",
        svg: {
          height: "2rem",
        },
      }),
    };

    const saveAccount=async(formData)=>{
      setLoading(true);
      const url = BASE_URL + "/settings/update-journal-voucher";
       await axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
      .then((res) => {
        setJournalVoucherList(res.data[0]);
        setJournalVoucherListForSearch(res.data[1]);
        setLoading(false);
        toast.success(`${_t(t("Voucher has been updated"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        history.push("/dashboard/manage/transaction/journal-voucher");
      }).catch((error) =>{
        setLoading(false);
        toast.error(`${_t(t("Please try again later"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      })
    }
    
    const handleSubmit=(e)=>{
      e.preventDefault();
      if(newRow.length > 0){
        let noFilledRows=newRow.length>0 && newRow.filter((v,i)=> v.account_name == "")
        if(noFilledRows.length>0){
          toast.error(`${_t(t("Please select account name"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        }else{
          let debit = totalDebit();
          let credit = totalCredit();
          if(debit === credit){
            const newArr = newRow.map((v,i) => {
              let obj={ 
            coa_account_id:v.account_name.id,
            supplier_id:v.supplier ? v.supplier.id : 0 ,
            cheque_date:v.cheque_date.getFullYear() + "-" + (parseInt(v.cheque_date.getMonth()) + 1) + "-" + v.cheque_date.getDate(),
            cheque_no:v.cheque_no,
            debit: isNaN(parseFloat(v.debit)) || v.debit==="" ? 0 : parseFloat(v.debit),
            credit:isNaN(parseFloat(v.credit)) || v.credit==="" ? 0 : parseFloat(v.credit),
            remarks:v.remarks
          }
          return obj;
        })
        let docDate = journalVoucher.doc_date.getFullYear() + "-" + (parseInt(journalVoucher.doc_date.getMonth()) + 1) + "-" + journalVoucher.doc_date.getDate();
        let saveObj={
          id:params.id,
          document_date:docDate,       
          remarks:journalVoucher.remarks,
          rows:newArr,
          total_credit:totalCredit(),
          total_debit: totalDebit()
        }
        // console.log("check save==>",saveObj);
        saveAccount(saveObj)
      }
      else{
        toast.error(`${_t(t("Debit and Credit must be equal"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      }
    }
  }else{
    toast.error(`${_t(t("Please add vouchers"))}`, {
      position: "bottom-center",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      className: "text-center toast-notification",
    });
  }
    
  }
  
  
    const handleDocDate=(date)=>{
      setJournalVoucher({
        ...journalVoucher,
        doc_date:date
      })
    }
    
    const handleRemarks = (remarks) =>{
      setJournalVoucher({
        ...journalVoucher,
        remarks
      })
    }
    
    // const [rowId,setRowId]=useState(0);
    const addNewRow=()=>{ 
      let newId = rowId.current     
      setNewRow((pre)=>[...pre,{
        id:newId,
        account_name:"",
        supplier:"",
        remarks:"",
        cheque_date:new Date(),
        cheque_no:"",
        debit:"",
        credit:""        
      }])
      rowId.current = rowId.current + 1;
    }
    const delRow=(id)=>{
      let row=[...newRow]
      row= row.filter((item,i)=>id!==item.id);
      setNewRow(row);
    }

    const handleAccountName=(index,acc)=>{
      newRow[index].account_name=acc  
      setNewRow([...newRow]);
    }

    const handleSetSupplier = (index,supplier) => {
      newRow[index].supplier=supplier  
      setNewRow([...newRow]);
    };

    const handleRowRemarks=(index,e)=>{
      newRow[index].remarks = e.target.value;
      setNewRow([...newRow]);
    }

    const handleChequeDate=(index,date)=>{
      newRow[index].cheque_date=date 
      setNewRow([...newRow]);
    }

    const handleChequeNo=(index,e)=>{
      newRow[index].cheque_no = e.target.value;
      setNewRow([...newRow]);
    }

    const handleDebit=(index,e)=>{
        newRow[index].debit=e.target.value;
        setNewRow([...newRow]) 
        if(e.target.value !== ""){
          newRow[index].credit = 0
          setNewRow([...newRow])
        }
    }

    const handleCredit=(index,e)=>{
        newRow[index].credit=e.target.value
        setNewRow([...newRow])
        if(e.target.value !== ""){
          newRow[index].debit = 0
          setNewRow([...newRow])
        } 
    }

    console.log("check newRow==>",newRow);
    const totalDebit=()=>{
      let total = 0;
      if (newRow !== null && newRow.length > 0) {
        newRow.map((item) => {
          // console.log("check total amount==>",(isNaN(item.amount) || item.amount=="" ? 0 : parseInt(item.amount)));
          // if(item.amount){
            total = total + (isNaN(item.debit) || item.debit=="" ? 0 : parseFloat(item.debit));
          // }
        });
      }
      // setTotalRowDebit(total)
      return total;
    }
    const totalCredit=()=>{
        let total = 0;
        if (newRow !== null && newRow.length > 0) {
          newRow.map((item) => {
            // console.log("check total amount==>",(isNaN(item.amount) || item.amount=="" ? 0 : parseInt(item.amount)));
            // if(item.amount){
              total = total + (isNaN(item.credit) || item.credit=="" ? 0 : parseFloat(item.credit));
            // }
          });
        }
        // setTotalRowCredit(total);
        return total;
      }

    return (
      <>
      <Helmet>
        <title>{_t(t("Edit Journal Voucher"))}</title>
      </Helmet>
  
      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
                <AccountsSidebar />
            </div>
            {/* left Sidebar ends */}
  
            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div
                  className="fk-scroll--pos-menu table-bottom-info-hide"
                  data-simplebar
                >
                  <div className="t-pl-15 t-pr-15">
                    {/* next page data spin loading */}
                    <div className={`${dataPaginating && "loading"}`}></div>
                    {/* spin loading ends */}
  
                    {/* Loading effect */}
                    {loading === true ? (
                      tableLoading()
                    ) : (
                      <div key="smtp-form">
                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {_t(t("Edit Journal Voucher"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6 col-lg-7">
                            <div className="row gx-3 align-items-center"></div>
                          </div>
                        </div>
  
                        {/* Form starts here */}
                        <form
                          className="row card p-2 mx-3 mb-5 table-text"
                          onSubmit={handleSubmit}
                        >
                          <div className="col-12">
                            <div className="row">                            
                            <div className="row">
                              <div className="form-group col-3 mt-2">
                                <div className="mb-2">
                                  <label
                                    htmlFor="invoice"
                                    className="control-label"
                                  >
                                    {_t(t("Document #"))}
                                  </label>
                                </div>
                                <div className="mb-2">
                                  <input
                                    disabled={true}
                                    type="text"
                                    className="form-control table-text"
                                    id="invoice"
                                    name="invoice"
                                    // onChange={handleChange}
                                    value={journalVoucher.doc_no}
                                    placeholder="Auto No"
                                  />
                                </div>
                              </div>
  
                              <div className="form-group col-3 text-right mt-2">
                                <div className="mb-2 text-left">
                                  <label
                                    htmlFor="purchaseDate"
                                    className="control-label"
                                  >
                                    {_t(t("Document Date"))}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="mb-2">
                                  <DatePicker
                                    selected={journalVoucher.doc_date}
                                    onChange={(date) => handleDocDate(date)}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    className="form-control table-text"
                                    placeholderText={_t(t("Document date"))}
                                    shouldCloseOnSelect={false}
                                    required
                                  />
                                </div>
                              </div>

                              <div className="form-group mt-2 col-6">
                            <div className="mb-2">
                              <label
                                htmlFor="description"
                                className="control-label"
                              >
                                {_t(t("Remarks"))}
                              </label>
                            </div>
                            <div className="mb-2">
                              <input
                                type="text"
                                className="form-control table-text"
                                id="description"
                                name="description"
                                onChange={(e)=>handleRemarks(e.target.value)}
                                value={journalVoucher.remarks}
                                placeholder="Short description"
                              />
                            </div>
                              </div>
                              </div>                            
                          </div>
                                <div className={`table-responsive pt-3 mt-2 pr-1`
                                } style={{maxHeight:"23rem",overflow: "auto"}}>                              
                                  <table className="table table-bordered-sm table-hover">
                                    <thead className="align-middle">
                                      <tr>
                                        {newRow.length==0 ?<th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                            <span className="t-mr-10" onClick={()=>addNewRow()}>
                                                <i className="fa fa-plus-circle cursor-pointer btn-add-icon" style={{fontSize:"22px"}} aria-hidden="true"></i>
                                            </span>
                                        </th>:<th style={{borderBottomColor:"lightgray"}}></th>}
                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border table-Child-bank"
                                        >
                                          <span style={{fontWeight:500}}>
                                            {_t(t("Account name"))}
                                          </span>
                                        </th>

                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border table-Child-bank"
                                        >
                                          <span style={{fontWeight:500}}>
                                            {_t(t("Supplier name"))}
                                          </span>
                                        </th>
                                      
                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:500}}>
                                            {_t(t("Remarks"))}
                                          </span>
                                        </th>

                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:500}}>
                                            {_t(t("Cheque Date"))}
                                          </span>
                                        </th>
  
                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:500}}>
                                            {_t(t("Cheque No"))}
                                          </span>
                                        </th>

                                        <th
                                        scope="col"
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                       >
                                        <span style={{fontWeight:500}}>
                                          {_t(t("Debit"))}
                                        </span>
                                       </th>

                                       <th
                                        scope="col"
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                       >
                                        <span style={{fontWeight:500}}>
                                          {_t(t("Credit"))}
                                        </span>
                                       </th>                                                                                                                    
                                      {newRow.length>0 && <th style={{borderBottomColor:"lightgray",minWidth:"60px"}}></th>}                                        
                                      </tr>
                                    </thead>
  
                                    <tbody className="align-middle">                                      
                                        {newRow.length > 0 ? newRow.map((v,i)=>{
                                            return <tr
                                            className="align-middle"
                                            key={i}
                                          >
                                            <td className="table-text text-capitalize align-middle text-center table-firstChild" style={{minWidth:"60px"}}>
                                            {i==(newRow.length-1) && <span className="t-mr-10" onClick={()=>addNewRow()}>
                                                <i className="fa fa-plus-circle btn-add-icon" style={{fontSize:"22px"}} aria-hidden="true"></i>
                                            </span>}&nbsp;
                                            <i className="fa fa-minus-circle cursor-pointer text-danger" style={{fontSize:"22px"}} onClick={()=>{delRow(v.id)}}></i>
                                            </td>
                                            <td className="table-text text-capitalize align-middle" style={{minWidth:"250px"}}>                                              
                                              <Select
                                                options={coa3ForSearch && coa3ForSearch}
                                                menuPlacement={i >= 4 ? "top" : "bottom"}
                                                components={makeAnimated()}
                                                getOptionLabel={(option) => option.level3_name}
                                                getOptionValue={(option) => option.id}
                                                value={v.account_name|| ""}
                                                classNamePrefix="select"
                                                onChange={(e)=>handleAccountName(i,e)}
                                                maxMenuHeight="12.8rem"
                                                placeholder={
                                                  _t(t("Account Name")) + ""
                                                }                                                
                                                styles={customStyle}
                                                // isMenuOpen={true}
                                                menuPosition="fixed"
                                                className="bank-table-input"                                                                                             
                                              />
                                            </td>

                                            <td className="table-text text-capitalize align-middle" style={{minWidth:"250px"}}>
                                            <Select
                                              menuPosition="fixed"
                                              menuPlacement={i >= 4 ? "top" : "bottom"} 
                                              options={supplierForSearch ? supplierForSearch : []}
                                              components={makeAnimated()}
                                              getOptionLabel={(option) => option.name}
                                              getOptionValue={(option) => option.id}
                                              value={v.supplier}
                                              classNamePrefix="select table-text"
                                              onChange={(e)=>handleSetSupplier(i,e)}
                                              maxMenuHeight="13rem"
                                              placeholder={
                                                _t(t("Please select supplier")) + ".."
                                              }
                                              styles={customStyle}                                  
                                            />
                                            </td>

                                            <td className="table-text text-capitalize align-middle text-center" style={{minWidth:"140px"}}>
                                          <input
                                            type="text"                                              
                                            // step="0.1"
                                            // min="0"
                                            //onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                            className="form-control text-center table-Input table-text"
                                          //   id={ingredientItem.id} 
                                          //   name={ingredientItem.id}
                                            onChange={(e)=>handleRowRemarks(i,e)}                                              
                                            placeholder="remarks"                                              
                                            value={v.remarks}
                                          />
                                          </td>

                                            <td className="table-text text-capitalize align-middle text-center" style={{minWidth:"120px"}}>
                                              <DatePicker
                                                selected={v.cheque_date}
                                                onChange={(date) => handleChequeDate(i,date)}
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                className="form-control table-text"
                                                placeholderText={_t(t("Cheque date"))}
                                                shouldCloseOnSelect={false}                                                
                                              />
                                            </td>
                                           
                                            <td className="table-text text-capitalize align-middle text-center" style={{minWidth:"130px"}}>
                                            <input
                                              type="number"
                                              // step="0.1"
                                              min="0"
                                              onKeyDown={(evt) => ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) && evt.preventDefault()}
                                              onWheel={(e)=>{e.target.blur()}}
                                              className="form-control text-center table-Input table-text"
                                            //   id={ingredientItem.id} 
                                            //   name={ingredientItem.id}
                                              onChange={(e)=>handleChequeNo(i,e)}
                                              value={v.cheque_no}
                                              placeholder="cheque no"                                                                                         
                                            />
                                            </td>
                                                                                        
                                            <td className="table-text text-capitalize align-middle text-center" style={{minWidth:"140px"}}>
                                            <input
                                              type="number"
                                              // step="0.1"
                                              min="0"
                                              onKeyDown={(evt) => ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) && evt.preventDefault()}
                                              onWheel={(e)=>{e.target.blur()}}
                                              className="form-control text-center table-Input table-text"
                                            //   id={ingredientItem.id} 
                                            //   name={ingredientItem.id}
                                              onChange={(e)=>handleDebit(i,e)}
                                            //   value={qtyOfItem && qtyOfItem[ingredientItem?.id] ? qtyOfItem[ingredientItem?.id] : ""}
                                              placeholder="0"
                                              required
                                              value={v.debit}
                                            />
                                            </td>
                                            <td className="table-text text-capitalize align-middle text-center" style={{minWidth:"140px"}}>
                                            <input
                                              type="number"
                                              // step="0.1"
                                              min="0"
                                              onKeyDown={(evt) => ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) && evt.preventDefault()}
                                              onWheel={(e)=>{e.target.blur()}}
                                              className="form-control text-center table-Input table-text"
                                            //   id={ingredientItem.id} 
                                            //   name={ingredientItem.id}
                                              onChange={(e)=>handleCredit(i,e)}                                            
                                              placeholder="0"
                                              required
                                              value={v.credit}
                                            />
                                            </td>
                                                                                                                                    
                                            <td className="table-text text-capitalize align-middle text-center" style={{minWidth:"60px"}}>
                                                <i className="fa fa-minus-circle cursor-pointer text-danger" style={{fontSize:"22px"}} onClick={()=>{delRow(v.id)}}></i>&nbsp;
                                                {i==(newRow.length-1) && <span className="t-mr-10" onClick={()=>addNewRow()}>
                                                    <i className="fa fa-plus-circle cursor-pointer btn-add-icon" style={{fontSize:"22px"}} aria-hidden="true"></i>
                                                </span>}
                                               
                                            </td>
                                      </tr>
                                        }):null}
                                    </tbody>
                                    {newRow.length > 0 &&
                                    <tfoot className="align-middle">
                                      <tr>
                                        <th
                                        // style={{minWidth:"100px"}}
                                          scope="col"
                                          colSpan="6"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        ></th>                                        
                                      
                                        <th
                                          style={{minWidth:"100px"}}
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border font-weight-bold"
                                        >
                                          <span style={{fontWeight:"bold"}}>
                                            {formatPrice(totalDebit())}
                                          </span>
                                        </th>
                                        <th
                                          style={{minWidth:"100px"}}
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border font-weight-bold"
                                        >
                                          <span style={{fontWeight:"bold"}}>
                                            {formatPrice(totalCredit())}
                                          </span>
                                        </th>                                                                                
                                                                                
                                        <th
                                        // style={{minWidth:"100px"}}
                                          scope="col"
                                          // colSpan="3"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        ></th> 
                                      </tr>
                                    </tfoot>}
                                  </table>                                  
                                </div>
                              {/* ),
                            ]} */}
  
                            <div className="form-group mt-4 pb-2">
                              <div className="col-lg-12">
                                <button
                                  className="btn btn-primary px-5 rounded"
                                  type="submit"
                                >
                                  {_t(t("Save"))}
                                </button>
                                <button
                                  className="btn btn-primary px-5 mx-2 rounded"
                                  onClick={()=>(history.push("/dashboard/manage/transaction/journal-voucher"))}
                                >
                                  {_t(t("Back"))}
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
        {/* main body ends */}        
    </>
    )
}

export default EditJournalVoucher;