import React, { useState,useContext } from 'react';
import {useParams } from "react-router-dom";
import { toast } from "react-toastify";
//functions
import {
  _t,
  getSystemSettings,
 
  // restaurantMenuLink,
} from "../../../../../functions/Functions";
import { SettingsContext } from "../../../../../contexts/Settings";
import { UserContext } from "../../../../../contexts/User";

const DropdownButton = ({
  value,
  getLabel,
  isSelected,
  setSelected,
  selectedSubItem,
  setSelectedSubItem,
  options,
  getSubItemLabel,
}) => {
  const [open, setOpen] = useState(false);
  const { order_id } = useParams();
  const { generalSettings } =
  useContext(SettingsContext);
  const { authUserInfo } = useContext(UserContext);

  return (
    <div className='mr-1 d-inline-block pos-menu-row btn-group' >
      <button
        disabled={authUserInfo.details.user_type === "call_center" && parseInt(getSystemSettings(generalSettings, "call_center_enable")) === 1}
        className={`dropdwn--btn btn bg2-success text-white ${isSelected ? 'selected': ''}`}
        // disabled={order_id}
        onClick={() => {
          if(order_id){
              
            toast.error("Can't change menu on edit complete the current order");
           
          }else{

            setSelected(value); 
            setTimeout(() => {
              const party =  options.find(item => item.name?.toLowerCase() === getLabel(value)?.toLowerCase());
             
              if(party === undefined || party === null){
                setOpen(true);
              }else{
                setOpen(false);
              }

              party && setSelectedSubItem(party)
            
              // if(getLabel(value).match(/dine(.*)in/i)){
              //   const dine = options && options.length ? (
              //   options.find((option)=>{
              //     //return DineIn.includes(option.name)
              //     return option.name.match(/dine(.*)in/i)
              // }))
              // :
              // null;
              // if(dine === undefined || dine === null){
              //   setOpen(true);
              // }else{
              //   setOpen(false);
              // }
              // dine && setSelectedSubItem(dine)
              // }else if(getLabel(value).match(/delivery/i)){
              //   const delivery = options && options.length ? (
              //   options.find((option)=>{
              //     //return Delivery.includes(option.name)
              //     return option.name.match(/delivery/i)
              // }))
              // :
              // null;
              // if(delivery === undefined || delivery === null){
              //   setOpen(true)
              // }else{
              //   setOpen(false);
              // }
              // delivery && setSelectedSubItem(delivery)
              // }else{
              //   const takeaway = options && options.length ? (
              //   options.find((option)=>{
              //     // return Takeaway.includes(option.name);
              //     return option.name.match(/takeaway/i)
              // }))
              // :
              // null;
              // if(takeaway === undefined || takeaway === null){
              //   setOpen(true)
              // }else{
              //   setOpen(false);
              // }
              // takeaway && setSelectedSubItem(takeaway)
              // }
            }, 50);
          }
          
        }}
      >
        {getLabel(value)}
      </button>
      <button
      className={`dropdwn1--btn btn bg2-success text-white ${isSelected ? 'selected': ''}`}
      disabled={authUserInfo.details.user_type === "call_center" && parseInt(getSystemSettings(generalSettings, "call_center_enable")) === 1}
      onClick={() => {
        if(order_id){
          toast.error("Can't change type on edit complete the current order");
        
        }else{
          setOpen(!open)
          setSelected(value);
        }
      
      }}
    >
    <i className="fa-sharp fa fa-caret-down ml-2 lg-text" />
    </button>
      {open && isSelected && options && options.length ?
        <ul className='pos--dropdown-list '>
          {options.map((option, index) => (
            <li
              key={index}
              className={isSelected && selectedSubItem === option ? 'selected' : ''}
              onClick={() => {
                if(order_id){
                    toast.error("Can't change type on edit complete the current order");
                    setOpen(false);
                }else{
                  setSelectedSubItem(option)
                  setOpen(false)
                }
              }}
            >{getSubItemLabel(option)}</li>
          ))}
        </ul>
        : null
      }
    </div>
  )
}

export default DropdownButton