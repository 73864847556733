import React, { useContext } from "react";
import {
  _t,
  getSystemSettings,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";
import { SettingsContext } from "../../../../../contexts/Settings";
import "./stock.css"

const GoodsReport = ({
    name,
    stockPrint,
    newFinishGoods,
    documentDate,
    newRow
}) => {
    const { t } = useTranslation();
    const {
        //common
        generalSettings,
      } = useContext(SettingsContext);
      let totalQty = 0
  return (
    
    <>
       {/* print */}
       <div className="d-none">
       <div ref={stockPrint}>
         {newFinishGoods && (
           <div className="fk-print" style={{margin:"20px 30px"}}>
             <div className="container">
               <div className="row">
                 <div className="col-12">
                   <div className="mx-auto" style={{ width: "90px" }}>
                     <img
                       src={`${getSystemSettings(
                         generalSettings,
                         "type_logo"
                       )}`}
                       style={{ width: "100%" }}
                     />
                   </div>
                   <div className="my-2 xlg-print-text-stock print-text-stock text-center text-capitalize print-text-stock--bold">
                     {newFinishGoods.branch && newFinishGoods.branch?.name}
                   </div>
                   <div className="my-2 xlg-print-text-stock print-text-stock text-center text-capitalize print-text-stock--bold">
                     {name}
                   </div>

                   <div className="mt-4 my-2 d-flex print-text-stock justify-content-between text-capitalize">
                     <div className="print-text-stock--bold w-50">Document No: {newFinishGoods.identity}</div>
                     <div className="print-text-stock--bold w-50">Document Date : {documentDate && documentDate?.toDateString()}</div>
                   </div>
                   
                   {newFinishGoods.supplier ? 
                   <div className="mb-2 my-2 print-text-stock text-capitalize print-text-stock--bold d-flex justify-content-between">
                    <div className="print-text-stock--bold">
                     Supplier Name :{" "}
                     {newFinishGoods.supplier && newFinishGoods.supplier?.name}
                     </div>
                   </div>:
                    null}

                  {newFinishGoods.description ? 
                   <div className="mb-4 my-2 print-text-stock text-capitalize print-text-stock--bold d-flex justify-content-between">
                    <div className="print-text-stock--bold">
                     Description :{" "}
                     {newFinishGoods.description && newFinishGoods.description}
                     </div>                    
                   </div>:
                    null}

                   <div className="mb-4 my-2 print-text-stock text-capitalize print-text-stock--bold d-flex justify-content-between">
                   {newFinishGoods.toBranch ? 
                    <div className="print-text-stock--bold w-50">
                     To Branch :{" "}
                     {newFinishGoods.toBranch && newFinishGoods.toBranch.name}
                     </div>
                   :
                   null}
                  {newFinishGoods.remarks ? 
                    <div className="print-text-stock--bold w-50">
                     Remarks :{" "}
                     {newFinishGoods.remarks && newFinishGoods.remarks}
                     </div>
                   :
                   null}                   
                   </div>

                    {newRow !== null && [
                     newRow.length > 0 && (
                       <div className=" mt-3">
                         <table className="table table-bordered-sm table-hover ">
                           <thead className="align-middle">
                             <tr>
                               <th
                                 scope="col"
                                 className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border table-Child"
                                 style={{minWidth:"180px"}}
                               >
                                 {_t(t("Name"))}
                               </th>
                               {

                               }
                               <th
                                 scope="col"
                                 className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                               >
                                 {_t(t("Qty"))}
                               </th>                                                              
                             </tr>
                           </thead>

                           <tbody className="align-middle">
                             {newRow?.map((item, index) => {
                                totalQty += parseInt(item.qty)
                               return (
                                 <tr className="align-middle" key={index}>
                                   <td className="print-text-stock text-capitalize border-dark border-1 align-middle text-center">
                                     {item?.item_name}
                                   </td>

                                   <td className="print-text-stock text-capitalize border-dark border-1 align-middle text-center">                                    
                                       {parseInt(item?.qty)}
                                   </td>                    
                                 </tr>
                               );
                             })}
                           </tbody>

                           <tfoot className="align-middle">                           
                             <tr>
                               <th
                                 scope="col"
                                 className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                               >
                                 {_t(t("Total"))}
                               </th>
                      
                               <th
                                 scope="col"
                                 className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                               >
                                 {totalQty}
                               </th>
                               </tr>                                                                                   
                           </tfoot>
                         </table>
                       </div>
                     ),
                   ]}                                      
                 </div>
               </div>
             </div>
           </div>
         )}
       </div>
     </div>
    </>
  )
}

export default GoodsReport;
