import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Switch from "react-switch";

//pages & includes
import ManageSidebar from "../ManageSidebar";

//functions
import {
  _t,
  getCookie,
  modalLoading,
  tableLoading,
  pagination,
  paginationLoading,
  showingData,
  searchedShowingData,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//3rd party packages
import { Helmet } from "react-helmet";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdDelete } from 'react-icons/md';

//context consumer
import { SettingsContext } from "../../../../../contexts/Settings";
// import { FoodContext } from "../../../../../contexts/Food";
import {RestaurantContext} from "../../../../../contexts/Restaurant"

const IngredientUnit = () => {
  const { t } = useTranslation();
  const history = useHistory();
  //getting context values here
  let {
    //common
    loading,
    setLoading,
  } = useContext(SettingsContext);

  let {
    //food group
    // getIngredientUnits,
    ingredientList,
    setIngredientList,
    // setPaginatedIngredientList,
    ingredientUnitForSearch,
    setIngredientUnitForSearch,

    // pagination,
    dataPaginating,
    setPaginatedIngredientUnit
  } = useContext(RestaurantContext);

  // States hook here
  //new group
  let [newIngUnit, setNewIngUnit] = useState({
    name: "",
    code: "",
    edit: false,
    status: true,
    editSlug: null,
    uploading: false,
  });

  //search result
  let [searchedIngUnit, setSearchedIngUnit] = useState({
    list: null,
    searched: false,
  });

  //useEffect == componentDidMount
  useEffect(() => {}, []);

  //set name hook
  const handleSetNewIngUnit = (e) => {
    setNewIngUnit({ ...newIngUnit, [e.target.name]: e.target.value });
  };

  //Save New paymentType
  const handleSaveNewIngUnit = async (e) => {
    e.preventDefault();
    setNewIngUnit({
      ...newIngUnit,
      uploading: true,
    });
    const ingUnitUrl = BASE_URL + `/settings/unit`;
    let formData = new FormData();
    formData.append("name", newIngUnit.name);
    formData.append("status", newIngUnit.status === true ? 1 : 0);
    formData.append("code", newIngUnit.code)
    try {
          const res = await axios
              .post(ingUnitUrl, formData, {
                  headers: { Authorization: `Bearer ${getCookie()}` },
              });
              setNewIngUnit({
              name: "",
              code:"",
              edit: false,
              editSlug: null,
              uploading: false,
          });
          setIngredientList(res.data[0]);
          setIngredientUnitForSearch(res.data[1]);
          setSearchedIngUnit({
              ...searchedIngUnit,
              list: res.data[1],
          });
          setLoading(false);
          toast.success(`${_t(t("Ingredient Unit has been added"))}`, {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
          });
          document.getElementById('close-btn').click();
      } catch (error) {
          setLoading(false);
          setNewIngUnit({
              ...newIngUnit,
              uploading: false,
          });
          if (error.response.data.errors) {
              if (error.response.data.errors.name) {
                  error.response.data.errors.name.forEach((item) => {
                      if (item === "A food group already exists with this name") {
                          toast.error(
                              `${_t(t("A food group already exists with this name"))}`,
                              {
                                  position: "bottom-center",
                                  autoClose: 10000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  className: "text-center toast-notification",
                              }
                          );
                      }
                  });
              } else {
                  toast.error(`${_t(t("Please try again"))}`, {
                      position: "bottom-center",
                      autoClose: 10000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      className: "text-center toast-notification",
                  });
              }
          }
      }
  };

  //set edit true & values
  const handleSetEdit = (id) => {
    let filterIngUnit = ingredientUnitForSearch.filter((item) => {
      return item.id === id;
    });
    console.log("filterIngUnit", filterIngUnit);
    setNewIngUnit({
      ...newIngUnit,
      id: filterIngUnit[0].id,
      name: filterIngUnit[0].name,
      code: filterIngUnit[0].code,
      status: filterIngUnit[0].status === 1 ? true : false,
      // input_key: filterIngUnit[0].input_key,
      // editSlug: filterIngUnit[0].slug,
      edit: true,
    });
  };

  //update food group
  const handleUpdateIngUnit = async (e) => {
    e.preventDefault();
    setNewIngUnit({
      ...newIngUnit,
      uploading: true,
    });
    const foodGroupUrl = BASE_URL + `/settings/unit/${newIngUnit.id}`;
    // let formData = new FormData();
    // formData.append("ing_unit_name", newIngUnit.name);
    // formData.append("ing_unit_code", newIngUnit.code)
    // formData.append("ing_unit_status", newIngUnit.status === true ? 1 : 0);
    // formData.append("editSlug", newIngUnit.editSlug);

    let formdata = {
      name: newIngUnit.name,
      code: newIngUnit.code,
      status: newIngUnit.status === true ? 1 : 0
    }

    formdata = JSON.stringify(formdata);
    try {
          const res = await axios
              .put(foodGroupUrl, formdata, {
                  headers: { Authorization: `Bearer ${getCookie()}`, 'content-type': 'text/json' },
              });
          setNewIngUnit({
              name: "",
              code: "",
              edit: false,
              editSlug: null,
              uploading: false,
          });
          setIngredientList(res.data[0]);
          setIngredientUnitForSearch(res.data[1]);
          setSearchedIngUnit({
              ...searchedIngUnit,
              list: res.data[1],
          });
          setLoading(false);
          toast.success(`${_t(t("Ingredient Unit has been updated"))}`, {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
          });
          document.getElementById('close-btn').click();
      } catch (error) {
          setLoading(false);
          setNewIngUnit({
              ...newIngUnit,
              uploading: false,
          });
          if (error.response.data.errors) {
              if (error.response.data.errors.name) {
                  error.response.data.errors.name.forEach((item) => {
                      if (item === "An ingredient unit already exists with this name") {
                          toast.error(
                              `${_t(t("An ingredient unit already exists with this name"))}`,
                              {
                                  position: "bottom-center",
                                  autoClose: 10000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  className: "text-center toast-notification",
                              }
                          );
                      }
                  });
              } else {
                  toast.error(`${_t(t("Please try again"))}`, {
                      position: "bottom-center",
                      autoClose: 10000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      className: "text-center toast-notification",
                  });
              }
          }
      }
  };

  //search food group here
  const handleSearch = (e) => {
    let searchInput = e.target.value.toLowerCase();
    if (searchInput.length === 0) {
      setSearchedIngUnit({ ...searchedIngUnit, searched: false });
    } else {
      let searchedList = ingredientUnitForSearch.filter((item) => {
        let lowerCaseItemName = item.name.toLowerCase();
        let lowerCaseCode = item.code.toLowerCase();
        return lowerCaseItemName.includes(searchInput) || lowerCaseCode.includes(searchInput);
      });
      setSearchedIngUnit({
        ...searchedIngUnit,
        list: searchedList,
        searched: true,
      });
    }
  };

  //delete confirmation modal of paymentType
  const handleDeleteConfirmation = (slug) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card card-body">
            <h1>{_t(t("Are you sure?"))}</h1>
            <p className="text-center">{_t(t("You want to delete this?"))}</p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary"
                onClick={() => {
                  handleDeleteIngredientUnit(slug);
                  onClose();
                }}
              >
                {_t(t("Yes, delete it!"))}
              </button>
              <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                {_t(t("No"))}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  //is stutus
  const handleStatus = (e) => {
    setNewIngUnit({
      ...newIngUnit,
      status: !newIngUnit.status
    })
    console.log("e", e);
  }

  //delete paymentType here
  const handleDeleteIngredientUnit = async (slug) => {
    setLoading(true);
    const ingUnitUrl = BASE_URL + `/settings/unit/${slug}`;
    try {
          const res = await axios
              .delete(ingUnitUrl, {
                  headers: { Authorization: `Bearer ${getCookie()}` },
              });
          setIngredientList(res.data[0]);
          setIngredientUnitForSearch(res.data[1]);
          setSearchedIngUnit({
              ...searchedIngUnit,
              list: res.data[1],
          });
          setLoading(false);
          toast.success(`${_t(t("Ingredient Unit has been deleted successfully"))}`, {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
          });
      } catch {
          setLoading(false);
          toast.error(`${_t(t("Please try again"))}`, {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
          });
      }
  };

  return (
    <>
      <Helmet>
        <title>{_t(t("Ingredient Unit"))}</title>
      </Helmet>

      {/* Add modal */}
      <div className="modal fade" id="addPaymentType" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <div className="fk-sm-card__content">
                <h5 className="text-capitalize fk-sm-card__title">
                  {!newIngUnit.edit
                    ? _t(t("Add New Ingredient Unit"))
                    : _t(t("Update Ingredient Unit"))}
                </h5>
              </div>
              <button
                type="button"
                id="close-btn"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {/* show form or show saving loading */}
              {newIngUnit.uploading === false ? (
                <div key="fragment-food-group-1">
                  <form
                    onSubmit={
                      !newIngUnit.edit
                        ? handleSaveNewIngUnit
                        : handleUpdateIngUnit
                    }
                  >
                    <div className="mb-1">
                      <label htmlFor="code" className="form-label">
                        {_t(t("Code"))}{" "}
                        <small className="text-danger">*</small>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="code"
                        name="code"
                        placeholder="e.g. pcs, kgs"
                        value={newIngUnit.code || ""}
                        required
                        onChange={handleSetNewIngUnit}
                      />
                    </div>


                    <div>
                      <label htmlFor="name" className="form-label">
                        {_t(t("Name"))}{" "}
                        <small className="text-danger">*</small>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        placeholder="e.g. Pieces, Kilograms, etc"
                        value={newIngUnit.name || ""}
                        required
                        onChange={handleSetNewIngUnit}
                      />
                    </div>

                    <div className="form-check mt-4 pl-0">
                      <Switch
                          checked={newIngUnit.status}
                          name="status"
                          onChange={(e) => {
                              handleStatus(e);
                          }}
                          height={22}
                          width={44}
                          offColor="#ee5253"
                          className="float-left"
                      />
                      <label
                        className="form-check-label pointer-cursor ml-2"
                        htmlFor="status"
                      >
                        {_t(t("Status"))}
                      </label>
                    </div>

                    <div className="mt-4 ">
                      <div className="row d-flex justify-content-end align-items-center">
                        <div className="col-3">
                          <button
                            type="submit"
                            className="btn btn-success w-100 xsm-text text-uppercase t-width-max rounded"
                          >
                            {!newIngUnit.edit
                              ? _t(t("Save"))
                              : _t(t("Update"))}
                          </button>
                        </div>
                        <div className="col-3">
                          <button
                            type="button"
                            className="btn btn-primary w-100 xsm-text text-uppercase t-width-max rounded"
                            data-dismiss="modal"
                          >
                            {_t(t("Close"))}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              ) : (
                <div key="fragment2">
                  <div className="text-center text-primary font-weight-bold text-uppercase">
                    {_t(t("Please wait"))}
                  </div>
                  {modalLoading(3)}
                  <div className="mt-4">
                    <div className="row d-flex justify-content-end align-items-center">
                      <div className="col-3">
                        <button
                          type="button"
                          className="btn btn-success w-100 xsm-text text-uppercase t-width-max rounded"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          {!newIngUnit.edit ? _t(t("Save")) : _t(t("Update"))}
                        </button>
                      </div>
                      <div className="col-3">
                        <button
                          type="button"
                          className="btn btn-primary w-100 xsm-text text-uppercase t-width-max rounded"
                          data-dismiss="modal"
                        >
                          {_t(t("Close"))}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Add modal Ends*/}

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ManageSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div className="fk-scroll--pos-menu" data-simplebar>
                  <div className="t-pl-15 t-pr-15">
                    {/* Loading effect */}
                    {/* {newIngUnit.uploading === true || loading === true ? (
                      tableLoading()
                    ) : ( */}
                      <div key="fragment3">
                        {/* next page data spin loading */}
                        <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                        <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        {/* spin loading ends */}

                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {!searchedIngUnit.searched
                                    ? _t(t("Ingredient Units List"))
                                    : _t(t("Search Result"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6 col-lg-7">
                            <div className="row gx-3 align-items-center">
                              {/* Search group */}
                              <div className="col-md-9 t-mb-15 mb-md-0">
                                <div className="input-group">
                                  <div className="form-file">
                                    <input
                                      type="text"
                                      className="form-control border-0 form-control--light-1 rounded-left"
                                      placeholder={_t(t("Search")) + ".."}
                                      onChange={handleSearch}
                                    />
                                  </div>
                                  <button
                                    className="btn btn-primary rounded-right"
                                    type="button"
                                  >
                                    <i
                                      className="fa fa-search"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div>
                              </div>

                              {/* Add group modal trigger button */}
                              <div className="col-md-3 text-md-right">
                                <button
                                  type="button"
                                  className="btn btn-primary xsm-text text-uppercase btn-lg btn-block rounded"
                                  data-toggle="modal"
                                  data-target="#addPaymentType"
                                  onClick={() => {
                                    setNewIngUnit({
                                      ...newIngUnit,
                                      edit: false,
                                      uploading: false,
                                    });
                                  }}
                                >
                                  {_t(t("add new"))}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Table */}
                        <div className="table-responsive">
                          <table className="table table-bordered table-hover min-table-height">
                            <thead className="align-middle">
                              <tr>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("S/L"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Code"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Name"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Status"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Action"))}
                                </th>
                              </tr>
                            </thead>
                            <tbody className="align-middle">
                              {/* loop here, logic === !search && haveData && haveDataLegnth > 0*/}
                              {!searchedIngUnit.searched
                                ? [
                                    ingredientList && [
                                      (ingredientList.data.length === 0) ? (
                                        <tr className="align-middle">
                                          <td
                                            scope="row"
                                            colSpan="6"
                                            className="xsm-text align-middle text-center"
                                          >
                                            {_t(t("No data available"))}
                                          </td>
                                        </tr>
                                      ) : (
                                        ingredientList.data.map(
                                          (item, index) => {
                                            return (
                                              <tr
                                                className="align-middle"
                                                key={index}
                                              >
                                                <th
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {index +
                                                    1 +
                                                    (ingredientList.current_page -
                                                      1) *
                                                      ingredientList.per_page}
                                                </th>

                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  {item.code}
                                                </td>

                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  {item.name}
                                                </td>

                                                <td className="xsm-text text-uppercase align-middle text-center font-weight-bold">
                                                  {item.status === 1
                                                    ? <span className="text-success">{_t(t("On"))}</span>
                                                    : <span className="text-danger">{_t(t("Off"))}</span>
                                                  }
                                                </td>

                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  {/* <div className="dropdown">
                                                    <button
                                                      className="btn t-bg-clear t-text-dark--light-40"
                                                      type="button"
                                                      data-toggle="dropdown"
                                                    >
                                                      <i className="fa fa-ellipsis-h"></i>
                                                    </button>
                                                    <div className="dropdown-menu">
                                                      <button
                                                        className="dropdown-item sm-text text-capitalize"
                                                        onClick={() =>
                                                          handleSetEdit(
                                                            item.id
                                                          )
                                                        }
                                                        data-toggle="modal"
                                                        data-target="#addPaymentType"
                                                      >
                                                        <span className="t-mr-8">
                                                          <i className="fa fa-pencil"></i>
                                                        </span>
                                                        {_t(t("Edit"))}
                                                      </button>

                                                      <button
                                                        className="dropdown-item sm-text text-capitalize"
                                                        onClick={() => {
                                                          handleDeleteConfirmation(
                                                            item.id
                                                          );
                                                        }}
                                                      >
                                                        <span className="t-mr-8">
                                                          <i className="fa fa-trash"></i>
                                                        </span>
                                                        {_t(t("Delete"))}
                                                      </button>
                                                    </div>
                                                  </div> */}
                                                  <button
                                                  className="btn btn-primary ml-2 rounded-sm del-btn"
                                                  onClick={() =>
                                                    handleSetEdit(
                                                      item.id
                                                    )
                                                  }
                                                  data-toggle="modal"
                                                  data-target="#addPaymentType"                                                                                                    
                                                  >                                                    
                                                    <i className="fa fa-pencil edit-icon" aria-hidden="true"></i>                                                    
                                                 </button>
                                                  <button
                                                  className="btn btn-danger ml-2 rounded-sm del-btn"
                                                  onClick={()=>handleDeleteConfirmation(item.id)}                                                    
                                                  >                                                    
                                                    <MdDelete className="del-icon" />                                                    
                                                 </button>
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )
                                      ),
                                    ],
                                  ]
                                : [
                                    /* searched data, logic === haveData*/
                                    searchedIngUnit && [
                                      searchedIngUnit.list.length === 0 ? (
                                        <tr className="align-middle">
                                          <td
                                            scope="row"
                                            colSpan="6"
                                            className="xsm-text align-middle text-center"
                                          >
                                            {_t(t("No data available"))}
                                          </td>
                                        </tr>
                                      ) : (
                                        searchedIngUnit.list.map(
                                          (item, index) => {
                                            return (
                                              <tr
                                                className="align-middle"
                                                key={index}
                                              >
                                                <th
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {index +
                                                    1 +
                                                    (ingredientList.current_page -
                                                      1) *
                                                      ingredientList.per_page}
                                                </th>

                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  {item.code}
                                                </td>

                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  {item.name}
                                                </td>

                                                <td className="xsm-text text-uppercase align-middle text-center font-weight-bold">
                                                  {item.status === 1
                                                    ? <span className="text-success">{_t(t("On"))}</span>
                                                    : <span className="text-danger">{_t(t("Off"))}</span>
                                                  }
                                                </td>

                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  {/* <div className="dropdown">
                                                    <button
                                                      className="btn t-bg-clear t-text-dark--light-40"
                                                      type="button"
                                                      data-toggle="dropdown"
                                                    >
                                                      <i className="fa fa-ellipsis-h"></i>
                                                    </button>
                                                    <div className="dropdown-menu">
                                                      <button
                                                        className="dropdown-item sm-text text-capitalize"
                                                        onClick={() =>
                                                          handleSetEdit(
                                                            item.id
                                                          )
                                                        }
                                                        data-toggle="modal"
                                                        data-target="#addPaymentType"
                                                      >
                                                        <span className="t-mr-8">
                                                          <i className="fa fa-pencil"></i>
                                                        </span>
                                                        {_t(t("Edit"))}
                                                      </button>

                                                      <button
                                                        className="dropdown-item sm-text text-capitalize"
                                                        onClick={() => {
                                                          handleDeleteConfirmation(
                                                            item.id
                                                          );
                                                        }}
                                                      >
                                                        <span className="t-mr-8">
                                                          <i className="fa fa-trash"></i>
                                                        </span>
                                                        {_t(t("Delete"))}
                                                      </button>
                                                    </div>
                                                  </div> */}
                                                  <button
                                                  className="btn btn-primary ml-2 rounded-sm del-btn"
                                                  onClick={() =>
                                                    handleSetEdit(
                                                      item.id
                                                    )
                                                  }
                                                  data-toggle="modal"
                                                  data-target="#addPaymentType"                                                                                                    
                                                  >                                                    
                                                    <i className="fa fa-pencil edit-icon" aria-hidden="true"></i>                                                    
                                                 </button>
                                                  <button
                                                  className="btn btn-danger ml-2 rounded-sm del-btn"
                                                  onClick={()=>handleDeleteConfirmation(item.id)}                                                    
                                                  >                                                    
                                                    <MdDelete className="del-icon" />                                                    
                                                 </button>
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )
                                      ),
                                    ],
                                  ]}
                            </tbody>
                          </table>
                        </div>
                      </div>                 
                  </div>
                </div>
              </div>

              {/* pagination loading effect */}
              {newIngUnit.uploading === true || loading === true
                ? paginationLoading()
                : [
                    // logic === !searched
                    !searchedIngUnit.searched ? (
                      <div key="fragment4">
                        <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                          <div className="row align-items-center t-pl-15 t-pr-15">
                            <div className="col-md-7 t-mb-15 mb-md-0">
                              {/* pagination function */}
                              {pagination(ingredientList, setPaginatedIngredientUnit)}
                            </div>
                            <div className="col-md-5">
                              <ul className="t-list d-flex justify-content-md-end align-items-center">
                                <li className="t-list__item">
                                  <span className="d-inline-block sm-text">
                                    {showingData(ingredientList)}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      // if searched
                      <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                        <div className="row align-items-center t-pl-15 t-pr-15">
                          <div className="col-md-7 t-mb-15 mb-md-0">
                            <ul className="t-list d-flex">
                              <li className="t-list__item no-pagination-style">
                                <button
                                  className="btn btn-primary btn-sm"
                                  onClick={() =>
                                    setSearchedIngUnit({
                                      ...searchedIngUnit,
                                      searched: false,
                                    })
                                  }
                                >
                                  {_t(t("Clear Search"))}
                                </button>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-5">
                            <ul className="t-list d-flex justify-content-md-end align-items-center">
                              <li className="t-list__item">
                                <span className="d-inline-block sm-text">
                                  {searchedShowingData(
                                    searchedIngUnit,
                                    ingredientUnitForSearch
                                  )}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    ),
                  ]}
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default IngredientUnit;
