import React, { useState } from "react";
import "./PasscodeAuthenticator.css";

const PasscodeAuthenticator = ({
  show,
  correctPasscode,
  onSuccess,
  // setShow,
  hanleCancelAuthenticate,
  right,
  setComponentUnlock,
  thisComponent
}) => {
  const [inputPass, setInputPass] = useState("");
  const [error, setError] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    console.log("thisComponent", correctPasscode,inputPass);
    if (!inputPass) setError("Please enter password before submitting.");
    else if (inputPass !== correctPasscode) {
      setError("Incorrect password. Try again.");
      setInputPass("");
    } else {
      
      setComponentUnlock(thisComponent)
      setError("");
      onSuccess();
    }
  };

  return show ? (
    <>
      <div className={`passcode-popup ${right ? 'right' : ''}`}>
        <form onSubmit={onSubmit} autoComplete='off'>
          <div className="d-flex flex-row">
            <label htmlFor="passcode" className="flex-grow-1">
              Enter password
            </label>
            <span
              role="button"
              className="text-danger lg-text"
              onClick={() => hanleCancelAuthenticate()}
            >
              <i className="la la-close" />
            </span>
          </div>
          <input
            type="text"
            className="secure-password-field"
            name="passcode"
            id="passcode"
            placeholder="Password"
            autoFocus
            value={inputPass}
            onChange={(e) => setInputPass(e.target.value)}
          />
          <button>Submit</button>
          {error ? (
            <p className="text-danger text-left font-bold error-container mb-0">
              {error}
            </p>
          ) : null}
        </form>
      </div>
      <div className="overlay-bg"></div>
    </>
  ) : null;
};

export default PasscodeAuthenticator;
