import React, { useState, useEffect,useContext, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Select, { createFilter } from "react-select";
import makeAnimated from "react-select/animated";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../../../../../../BaseUrl";
import axios from "axios";
import {
  _t,
  getCookie,
  getSystemSettings,
  customStyle
} from "../../../../../../functions/Functions";
import {UserContext} from "../../../../../../contexts/User";
import { RestaurantContext } from "../../../../../../contexts/Restaurant";
import { SettingsContext } from "../../../../../../contexts/Settings";
import MultiAddress from "./MultiAddress";

const CallCenterModal = ({
  modal9Show,
  setModal9Show,
  setModalOrderType,
  setOrderDetails,
  orderDetails,
  selectedParty,
  selectedDeliveryType,
  customer,
  setCustomer,
  setCustomerApi,
  setAddressList,
  addressList,
  newSettings,
  handleSetBranch,
  zoneArea,
  setZoneArea,
  area,
  setArea,
  setDeliveryCharges,
  selectedBranch,
  workPeriodScreen
}) => {    
    const {
        //waiter
        customerForSearch,
        authUserInfo
      } = useContext(UserContext);
      const {
        branchForSearch,
        areaList,
        branchForListSearch,
      } = useContext(RestaurantContext);
      const {
        generalSettings,
      } = useContext(SettingsContext);
      const nameInput = useRef(false);
      //const [customers, setCustomers] = useState();
      const duplicateRef = useRef(null);
      const [showCustomer,setShowCustomer] = useState(false);
      const [loading,setLoading] = useState(false);
      const { order_id } = useParams();
      //const [filterCustomer,setFilterCustomer] = useState([])
    
      const customerFilter = {
        ignoreCase: true,
        ignoreAccents: true,
        matchFrom: 'any',
        stringify: option => `${option.label} ${ option.value}`,
        trim: true,
      }
      useEffect(() => {
        if (
            (selectedParty &&
            selectedParty?.show_ref_no === 1) &&
            (selectedParty.name.match(/food(.*)panda/i))
        ) {
          nameInput.current = true;
          setTimeout(() => {  
            setCustomer({
              ...customer,
              name:"Food Panda",
              phn_no: "00000000000",
            }) 
          },10);
        } else {
          nameInput.current = false;
        }
      
        if (order_id) {
          if(orderDetails.customer){
            setCustomer({
              ...customer,
              name: orderDetails.customer?.name,
              email: orderDetails.customer?.email,
              phn_no: orderDetails.customer?.phn_no,
              address: orderDetails.customer?.address,
              ref_no: orderDetails.customer?.ref_no,
              alt_phn_no: orderDetails.customer?.alt_phn_no,
            });
          }
         
        }
      }, [selectedParty,orderDetails?.branch]);
     console.log("Abcd44",customer)
      // submit customer info
      const handleCustomer = async(e) => {
        e.preventDefault();
          const isCallCenterUser = authUserInfo.details.user_type === "call_center";
          const isCallCenterEnabled = parseInt(getSystemSettings(generalSettings, "call_center_enable")) === 1;
          if(isCallCenterEnabled && isCallCenterUser){
            if(selectedDeliveryType && (selectedDeliveryType.name.toLowerCase() === "delivery") && (zoneArea === "" || zoneArea === null)){
              toast.error("Please select area");
              return false
            }
            if(newSettings.workPeriod === null || workPeriodScreen == 0){
              toast.error("Please select branch with open workperiod!");
              return false
            }
            if(customer.name == "" || customer.phn_no == ""){
              toast.error(`Please fill customer ${customer.name == "" ? "name" : ""} ${ customer.name == "" && customer.phn_no == "" ? "&" : ""} ${customer.phn_no == "" ? "phone number" : ""}`);
              return false
            }
          }
          setLoading(true);
        const customerValidation =  order_id || (customer.ref_no?.length !== undefined &&
          customer.ref_no?.length !== 0 && customer.ref_no?.length  >= selectedParty.ref_min &&
          customer.ref_no?.length <= selectedParty.ref_max);
          const customerInfo = {
            name: customer.name ? customer.name : "",
            email: customer.email ? customer.email : "",
            number: customer.phn_no ? customer.phn_no : "",
            address: customer.address ? customer.address : "",
            alt_phn_no: orderDetails.customer?.alt_phn_no ?  orderDetails.customer.alt_phn_no : "",
            ref_no: customer.ref_no ? customer.ref_no : "",
          };
    
          const customerOnEdit = {
            ...orderDetails.customer,
            ...customer,
            name: customer.name ? customer.name :"",
            email: customer.email ? customer.email : "",
            address: customer.address ? customer.address : "",
            alt_phn_no: orderDetails.customer?.alt_phn_no ?  orderDetails.customer.alt_phn_no : "",
            ...((!orderDetails.customer?.id) ? {number: customer.phn_no ? customer.phn_no : ""} :  {phn_no: customer.phn_no ? customer.phn_no :""}),
            ref_no: customer.ref_no ? customer.ref_no : ""
          }
    
          const setCustomerInfo = {
            ...orderDetails,
            newCustomerInfo: customerInfo,
          }
    
          const setCustomers = {
            ...orderDetails,
            customer: customerOnEdit,
          }
          
        const updateCustomerInfo = () => {
          if (order_id) {
            setOrderDetails(setCustomers);
          } else {
            setOrderDetails(setCustomerInfo);
          }
          setCustomer({
            ...customer,
            is_confirm: true,
          });
          setModal9Show(false);
        };
    
        const updateCustomerFoodPanda = () =>{
    
          if (order_id) {
            setOrderDetails(setCustomers);
            setModal9Show(false);
          } else {
            let dupRef = duplicateRef.current;
            if(dupRef === "exist"){
              setCustomer({
                ...customer,
                ref_no: "",
                is_confirm: false,
              });
              setOrderDetails(setCustomerInfo);
              toast.error("Ref no is already exist!");
            }else{
              setOrderDetails(setCustomerInfo);
              setModal9Show(false);
            }
          }
        }
                                    
        if (
            selectedParty &&
            parseInt(selectedParty.show_ref_no) === 1 &&
            (selectedParty.name.match(/food(.*)panda/i))
        ) {
          //duplicate ref no api
          const refUrl = BASE_URL + "/settings/check-duplicate-ref-no/" + customer.ref_no;
          await axios
          .get(refUrl, {
            headers: { Authorization: `Bearer ${getCookie()}` },
          })
          .then((res) => {
            duplicateRef.current = res.data;
          })
          .catch((err)=>console.log(err));
    
          if (
            (customer.name !== "" &&
            customerValidation)
          ) {
           
            updateCustomerFoodPanda()
           
          } else {
            
            toast.error(`Please fill ${customer.name == "" ? customer.name : ""} ${!customerValidation ? "ref no between " + selectedParty.ref_min + "-" +selectedParty.ref_max + " digits." : ""}`);
          }
        }else if(
          selectedParty && parseInt(selectedParty.show_ref_no) === 1
        ){
         if(selectedDeliveryType?.name.toLowerCase() === "delivery"){
          if (  
            customerValidation && customer.name && customer.name !== "" && customer.phn_no !== ""
          ) {
            updateCustomerInfo()
          } else {
            
            toast.error(`Please fill customer ${customer.name == "" ? "name" : ""} ${ customer.name == "" && customer.phn_no == "" ? "&" : ""} ${customer.phn_no == "" ? "phone number" : ""} ${!customerValidation ? " ref no between " + selectedParty.ref_min + "-" + selectedParty.ref_max + " digits." : ""}`);
          }
         }else{
           if (
             customerValidation
           ) {
             updateCustomerInfo()
            } else {
            
             toast.error(`Please fill ref no between ${selectedParty.ref_min}-${selectedParty.ref_max} digits.`);
           }
         }
        }
         else {
          if((selectedDeliveryType?.name.toLowerCase() === "delivery") && (customer.name === "" || customer.phn_no === "")){
            toast.error(`Please fill customer ${customer.name == "" ? "name" : ""} ${ customer.name == "" && customer.phn_no == "" ? "&" : ""} ${customer.phn_no == "" ? "phone number" : ""}`);
          }else{
            updateCustomerInfo();
          }
        }
        setLoading(false);
      };
      
      //search customer
      const customerSearch = (customers) => {
          
          if(customers.address_book.length > 1 ){
            setShowCustomer(true);
            setAddressList(customers.address_book);
          }else{
            setShowCustomer(false);
            setAddressList([]);
          }
          setCustomer({
            ...customers,
            name: customers.name,
            email: customers.email ? customers.email : "",
            phn_no: customers.phn_no ? customers.phn_no : "",
            alt_phn_no: customers?.alt_phn_no ?  customers.alt_phn_no : "",
            address: customers.address_book.length > 1 ? customers.address_book[0].address : customers.address ? customers.address : "",
            ref_no:"",
          });
          setCustomerApi(0)
        
      };
    
      // input type
      const handleInputChange = (input) =>{
        if(input && input !== ""){
          setCustomer({
            ...customer,
            phn_no: input,
          });
        }
      }
    
      // select area
      const handleSetArea = async(area)=>{
       setArea(area)
       const areaUrl = BASE_URL + "/settings/get-area-zone-details/" + area.area_id;
       await axios
       .get(areaUrl, {
         headers: { Authorization: `Bearer ${getCookie()}` },
       })
       .then((res) => {
         const data = res.data.details
         setZoneArea(data);
         handleSetBranch(branchForListSearch[data.branch_id]);
         if(data.free_delivery === 1){
           setDeliveryCharges(0);
         }else{
           setDeliveryCharges(data.fee);
         }
       
       })
       .catch((err)=>console.log(err));
      }
   
  return (
    <>
    <Modal
      show={modal9Show}
      onHide={() => {
        const isCallCenterUser = authUserInfo.details.user_type === "call_center";
        const isCallCenterEnabled = parseInt(getSystemSettings(generalSettings, "call_center_enable")) === 1;
        if(isCallCenterEnabled && isCallCenterUser){
          if(selectedDeliveryType && (selectedDeliveryType.name.toLowerCase() === "delivery") && (zoneArea === "" || zoneArea === null)){
            toast.error("Please select area");
            return false
          }
          if(newSettings.workPeriod === null || workPeriodScreen == 0){
            toast.error("Please select branch with open workperiod!");
            return false
          }
          if(customer.name == "" || customer.phn_no == ""){
            toast.error(`Please fill customer ${customer.name == "" ? "name" : ""} ${ customer.name == "" && customer.phn_no == "" ? "&" : ""} ${customer.phn_no == "" ? "phone number" : ""}`);
            return false
          }
        }
        if(selectedParty && parseInt(selectedParty.show_ref_no) === 1 && customer.is_confirm === false){
           toast.error("Please press confirm button");
           return false;
        }
        toast.error("Please press confirm button");
        setModal9Show(false);
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
     
    >
      <Modal.Header closeButton className="customer-header">
        <Modal.Title className="customer-title" id="contained-modal-title-vcenter">
          {/* Modal Header */}
          <div className="container-fluid mt-1">
            <h4 className="fw-bold mb-0 input-field" id="exampleModalLabel">
              Call Center: Customer
            </h4>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Modal Body */}
        <div className="modal-body pt-0">
          <div className="container-fluid" style={{ paddingLeft: "0px" }}>
            
            <div className="row">
              <div className="col-6 mr-2">
                <label
                  className="col-form-label fw-bold d-flex justify-content-start"
                >
                  By Name / By Phone
                </label>
                <Select
                  isDisabled={selectedParty && selectedParty.name?.match(/food(.*)panda/i)}
                  options={customerForSearch}
                  components={makeAnimated()}
                  getOptionLabel={(option) => option.name_phone}
                  getOptionValue={(option) => option.id}
                  classNamePrefix="select"
                  filterOption={createFilter(customerFilter)}
                  className="xsm-text"
                  onInputChange={handleInputChange}
                  onChange={customerSearch}
                  maxMenuHeight="200px"
                  placeholder="Customer Search"
                  styles={customStyle}
                />
              </div>
              {
                addressList && addressList.length > 0 &&
                <div className="col-3 d-flex align-items-end mb-1">
                  <button className="btn btn-sm btn-primary" onClick={()=>setShowCustomer(true)}>Multi Address</button>
                </div>
              }
            </div>
        
              <div className="row">
               {
                selectedDeliveryType && (selectedDeliveryType.name.toLowerCase() === "delivery") && (
                  <div className="col-6">
                    <label
                      for="recipient-name"
                      className="col-form-label fw-bold d-flex justify-content-start"
                    >
                      Select Area
                    </label>
                    <Select
                      options={areaList && areaList}
                      components={makeAnimated()}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.area_id}
                      value={area ? area : ""}
                      classNamePrefix="select"
                      className="xsm-text"
                      onChange={handleSetArea}
                      maxMenuHeight="200px"
                      placeholder="Area"
                      styles={customStyle}
                    />
                    </div>
                )
               }
                  <div className="col-6">
                  <label
                    for="recipient-name"
                    className="col-form-label fw-bold d-flex justify-content-start"
                  >
                    Select Branch
                  </label>
                  <Select
                    isDisabled={selectedDeliveryType && selectedDeliveryType.name.toLowerCase() === "delivery"}
                    options={branchForSearch}
                    components={makeAnimated()}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    classNamePrefix="select"
                    className="xsm-text"
                    onChange={handleSetBranch}
                    maxMenuHeight="200px"
                    placeholder="Branch"
                    styles={customStyle}
                    value={selectedBranch ? selectedBranch : ""}
                  />
                </div>
              </div>
            
            <h4 className="d-flex justify-content-start fw-bold mb-1 mt-3">
              Add Customer
            </h4>
            <form 
            onSubmit={handleCustomer}
            >
              <div className="row">
                <div className="col-4">
                  <label
                    for="recipient-name"
                    className="col-form-label fw-bold d-flex justify-content-start p-0"
                  >
                    Name
                  </label>
                  <input
                    disabled={nameInput.current}
                    type="text"
                    className="form-control mb-2 customer-input"
                    id="recipient-name"
                    placeholder="Name"
                    onChange={(e) =>{
                      setCustomerApi(1);
                      setCustomer({
                        ...customer,
                        name: e.target.value
                      });
                    }}
                    value={customer.name}
                  />
                </div>
                <div className="col-4">
                  <label
                    for="phone"
                    className="col-form-label fw-bold d-flex justify-content-start p-0"
                  >
                    Phone
                  </label>
                  <input
                    //type="number"
                    type="text" 
                    maxlength="11" 
                    pattern="^\+?\d{0,9}-?\d{0,9}$"
                    className="form-control customer-input"
                    onKeyDown={(evt) => ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) && evt.preventDefault()}
                    onWheel={(e) => e.target.blur()}  
                    id="phone"
                    placeholder="0304***"
                    onChange={(e) => {
                      setCustomerApi(1);
                      setCustomer({
                        ...customer,
                        phn_no: e.target.value
                      });
                    } }
                    value={customer.phn_no}
                  />
                </div>
                <div className="col-4">
                  <label
                    for="alt-no"
                    className="col-form-label fw-bold d-flex justify-content-start p-0"
                  >
                    Alt Phone
                  </label>
                  <input
                    //type="number"
                    type="text" 
                    maxlength="11" 
                    pattern="^\+?\d{0,9}-?\d{0,9}$"
                    className="form-control customer-input"
                    onKeyDown={(evt) => ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) && evt.preventDefault()}
                    onWheel={(e) => e.target.blur()}  
                    id="alt-no"
                    placeholder="0304***"
                    onChange={(e) => {
                      setCustomerApi(1);
                      setCustomer({
                        ...customer,
                        alt_phn_no: e.target.value
                      });
                    } }
                    value={customer.alt_phn_no}
                  />
                </div>
               </div>
                <div className="row">
                
                <div className="col-6">
                  <label
                    for="email"
                    className="col-form-label fw-bold d-flex justify-content-start p-0"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control customer-input"
                    id="email"
                    placeholder="Email"
                    onChange={(e) =>  setCustomer({
                      ...customer,
                      email: e.target.value
                    })}
                    value={customer.email || ""}
                  />
                </div>
                <div className="col-6">
                  <label
                    for="ref"
                    className="col-form-label fw-bold d-flex justify-content-start p-0"
                  >
                    Ref No
                  </label>
                  <input
                    // disabled={order_id}
                    type="text"
                    className="form-control customer-input"
                    id="ref"
                    placeholder="Ref No"
                    onChange={(e) =>  setCustomer({
                      ...customer,
                      ref_no: e.target.value,
                    })}
                    value={customer.ref_no}
                    minLength={
                      selectedParty &&
                      selectedParty.show_ref_no === 1 &&
                      selectedParty.ref_min > 0 && selectedParty.ref_min
                    }
                    maxLength={
                      selectedParty &&
                      selectedParty.show_ref_no === 1 &&
                      selectedParty.ref_max > 0 ? selectedParty.ref_max : 20
                      }
                      />
                      </div>
                      {/* address */}
                      <div className="row mt-2 pr-0">
                      <label
                        for="address"
                        className="col-form-label fw-bold d-flex justify-content-start pt-0 pb-0"
                      >
                        Delivery Address
                      </label>
                
                    <div className="col-12 pr-0">
                    <input
                      type="text"
                      className="form-control customer-input"
                      id="address"
                      placeholder="Address"
                      onChange={(e) =>{
                        setCustomerApi(1);
                        setCustomer({
                          ...customer,
                          address: e.target.value
                        });
                      } }
                      value={customer.address}
                    />
                  </div>
                </div>
              </div>
              <Modal.Footer
              style={{
                borderTop: "none",
                justifyContent: "space-between",
                display: "block",
                marginBottom:"-15px",
                paddingRight:"0"
              }}
            >
              {/* Modal Footer */}
              <div className="modal-footer" style={{ borderTop: "none",paddingBottom:"0",paddingRight:"0" }}>
                <div className="container-fluid " style={{ padding: "0px" }}>
                  <div className="row d-flex justify-content-end">
                  {
                    loading && (
                      <div class="spinner-border text-primary mt-1" role="status">
                       <span class="sr-only">Loading...</span>
                      </div>
                    )
                  }
                  <div className="col-sm-3 mb-1">
                    <button
                      type="button"
                      className="btn w-100 fw-bold rounded-25"
                      style={{
                        backgroundColor: "#939393",
                        color: "#fff",
                        borderRadius: "0.5rem",
                      }}
                      onClick={() => {
                        setModalOrderType(true);
                        setModal9Show(false)
                      }}
                    >
                      Back
                    </button>
                  </div>
                   
                    <div className="col-sm-3 mb-1">
                      <button
                        type="submit"
                        className="btn w-100 fw-bold rounded-25"
                        style={{
                          backgroundColor: "#0a8d6e",
                          color: "#fff",
                          borderRadius: "0.5rem",
                        }}
                       
                      >
                        CONFIRM
                      </button>
                    </div>
                    {
                      /*
                    <div className="col-md-3 mb-1">
                      <button
                        type="button"
                        className="btn w-100 fw-bold rounded-25"
                        style={{
                          backgroundColor: "#939393",
                          color: "#fff",
                          borderRadius: "0.5rem",
                        }}
                        onClick={() => setModal9Show(false)}
                      >
                        CANCEL
                      </button>
                    </div>
                      */}
                  </div>
                </div>
              </div>
            </Modal.Footer>
            </form>
          </div>
        </div>
      </Modal.Body>
     
    </Modal>
      {/*multi address Modal*/}
      <MultiAddress
      showCustomer={showCustomer}
      addressList={addressList}
      setCustomerApi={setCustomerApi}
      setCustomer={setCustomer}
      customer={customer}
      setShowCustomer={setShowCustomer}
      />
      </>
  )
}

export default CallCenterModal
