import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

//pages & includes
import ManageSidebar from "../ManageSidebar";
import Switch from "react-switch";

//functions
import {
  _t,
  getCookie,
  modalLoading,
  tableLoading,
  pagination,
  paginationLoading,
  showingData,
  searchedShowingData,
  formatPrice,
  currencySymbolLeft,
  currencySymbolRight,
} from "../../../../../functions/Functions";

import { useTranslation } from "react-i18next";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//3rd Expense packages
import { Helmet } from "react-helmet";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { MdDelete } from 'react-icons/md';

//context consumer
import { SettingsContext } from "../../../../../contexts/Settings";
import { UserContext } from "../../../../../contexts/User";
import { RestaurantContext } from "../../../../../contexts/Restaurant";

const Expense = () => {
  const { t } = useTranslation();
  const history = useHistory();
  //getting context values here
  let {
    //common
    loading,
    setLoading,
  } = useContext(SettingsContext);

  let { 
    orderTypeForSearch,
    setExpense,
    setExpenseForSearch,
    expense,
    expenseForSearch,
    getPaginatedExpense,
    dataPaginating,
  } = useContext(RestaurantContext);


  console.log("order type List", orderTypeForSearch);
  // States hook here
  //new customer
  let [newExpense, setNewExpense] = useState({
    name: "",
    status:true,
    edit: false,
    editSlug: null,
    uploading: false,
  });
  
  //search result
  let [searchedExpense, setsearchedExpense] = useState({
    list: null,
    searched: false,
  });

  const focusUsernameInputField = input => {
    if (input) {
      setTimeout(() => {input.focus()}, 300);
    }
  };

  //set name, phn no hook
  const handlesetNewExpense = (e) => {
    setNewExpense({ ...newExpense, [e.target.name]: e.target.value });
  };

   //set name, phn no hook
   const handlesetStatus = (e) => {
    setNewExpense({ ...newExpense, status: !newExpense.status });
  };

  //Save New customer
  const handleSaveNewExpense = (e) => {
    e.preventDefault();
   
    setNewExpense({
      ...newExpense,
      uploading: true,
    });

    if(newExpense.name === null || newExpense.name === ""){
      toast.error(
        `${_t(t("Please select branch"))}`,{
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }     
      );
      return false
    }
    const customerUrl = BASE_URL + `/settings/new-expense`;
    let formData = new FormData();
    formData.append("name", newExpense.name);

    formData.append("status", newExpense.status);
 
    return axios
      .post(customerUrl, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setNewExpense({
          name: "",
          status:true,
          edit: false,
          editSlug: null,
          uploading: false,
        });
        setExpense(res.data[0]);
        setExpenseForSearch(res.data[1]);
        setLoading(false);
        toast.success(`${_t(t("Expense has been added"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        document.getElementById('close-btn').click();
      })
      .catch((error) => {
        setLoading(false);
        setNewExpense({
          ...newExpense,
          uploading: false,
        });
        toast.error(
          `${_t(t("Please try again"))}`,
          {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );
      });
  };

  //set edit true & values
  const handleSetEdit = (slug) => {
    let Expense = expenseForSearch.filter((item) => {
      return item.slug === slug;
    });
    setNewExpense({
      ...newExpense,
      name: Expense[0].name,
      status: parseInt(Expense[0].status) === 1 ? true : false,
      editSlug: Expense[0].slug,
      edit: true,
    });
  };

  const handleUpdateStatus = (expense) =>{
    setLoading(true)
    let formData = {
      editSlug : expense.slug,
      status : !expense.status
    }

    const customerUrl = BASE_URL + `/settings/update-expense-status`;
    return axios
    .post(customerUrl, formData, {
      headers: { Authorization: `Bearer ${getCookie()}` },
    })
    .then((res) => {
     
      setExpense(res.data[0]);
      setExpenseForSearch(res.data[1]);
      setsearchedExpense({
        ...searchedExpense,
        list: res.data[1],
      });
      setLoading(false);
      toast.success(`${_t(t("Expense has been updated"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    })
    .catch((error) => {
      setLoading(false);

      toast.error(
        `${_t(t("Please try again"))}`,
        {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }
      );
    });
  }

  //update customer
  const handleUpdateExpense = (e) => {
    e.preventDefault();
    
    setNewExpense({
      ...newExpense,
      uploading: true,
    });

    if(newExpense.name === null || newExpense.name === ""){
      toast.error(
        `${_t(t("Please select branch"))}`,{
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }     
      );
      return false
    }
    const customerUrl = BASE_URL + `/settings/update-expense`;
    let formData = new FormData();
    formData.append("name", newExpense.name);
    formData.append("status", newExpense.status);
    formData.append("editSlug", newExpense.editSlug);
    return axios
      .post(customerUrl, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setNewExpense({
          name: "",
          status: true,
          edit: false,
          editSlug: null,
          uploading: false,
        });
   
        setExpense(res.data[0]);
        setExpenseForSearch(res.data[1]);
        setsearchedExpense({
          ...searchedExpense,
          list: res.data[1],
        });
        setLoading(false);
        toast.success(`${_t(t("Expense has been updated"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        document.getElementById('close-btn').click();
      })
      .catch((error) => {
        setLoading(false);
        setNewExpense({
          ...newExpense,
          uploading: false,
        });
        toast.error(
          `${_t(t("Please try again"))}`,
          {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );
       
      });
  };

  //search customers here
  const handleSearch = (e) => {
    let searchInput = e.target.value.toLowerCase();
    if (searchInput.length === 0) {
      setsearchedExpense({ ...searchedExpense, searched: false });
    } else {
      let searchedList = expenseForSearch.filter((item) => {
        //name
        let lowerCaseItemName = item.name?.toLowerCase();

        // //show header
        // let lowerCaseItemShowHeader =
        //   item.showHeader !== null && item.showHeader.toLowerCase();

        return (
          lowerCaseItemName.includes(searchInput)
        );
      });
      setsearchedExpense({
        ...searchedExpense,
        list: searchedList,
        searched: true,
      });
    }
  };

  //delete confirmation modal of waiter
  const handleDeleteConfirmation = (slug) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card card-body">
            <h1>{_t(t("Are you sure?"))}</h1>
            <p className="text-center">{_t(t("You want to delete this?"))}</p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary"
                onClick={() => {
                  handleDeleteExpense(slug);
                  onClose();
                }}
              >
                {_t(t("Yes, delete it!"))}
              </button>
              <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                {_t(t("No"))}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  //delete customer here
  const handleDeleteExpense = (slug) => {
    setLoading(true);
    const customerUrl = BASE_URL + `/settings/delete-expense/${slug}`;
    return axios
      .get(customerUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setExpense(res.data[0]);
        setExpenseForSearch(res.data[1]);
        setsearchedExpense({
          ...searchedExpense,
          list: res.data[1],
        });
        setLoading(false);
        toast.success(`${_t(t("Expense has been deleted successfully"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      })
      .catch(() => {
        setLoading(false);
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };

  const handleFormStateEmpty = () => {
    setNewExpense({
      name: "",
      status: true,
      edit: false,
      editSlug: null,
      uploading: false,
    })
  }

  return (
    <>
      <Helmet>
        <title>{_t(t("Expense"))}</title>
      </Helmet>

      {/* Add modal */}
      <div className="modal fade" id="addExpense" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <div className="fk-sm-card__content">
                <h5 className="text-capitalize fk-sm-card__title">
                  {!newExpense.edit
                    ? _t(t("Add new Expense"))
                    : _t(t("Update Expense"))}
                </h5>
              </div>
              <button
                type="button"
                id="close-btn"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {/* show form or show saving loading */}
              {newExpense.uploading === false ? (
                <div key="fragment-customer-1">
                  <form
                    onSubmit={
                      !newExpense.edit
                        ? handleSaveNewExpense
                        : handleUpdateExpense
                    }
                  >
                    <div>
                      <label htmlFor="name" className="form-label">
                        {_t(t("Name"))}{" "}
                        <small className="text-danger">*</small>
                      </label>
                      <input
                        ref={focusUsernameInputField}
                        
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        placeholder="e.g. office"
                        value={newExpense.name || ""}
                        required
                        onChange={handlesetNewExpense}
                      />
                    </div>

                    <div className="mt-3 d-flex align-items-center">
                        <label htmlFor="status" className="mr-3">
                         {_t(t("Status"))}{" "}
                        </label>
                        <Switch
                        checked={newExpense?.status === true}
                        name="status"
                        onChange={handlesetStatus}
                        height={22}
                        width={44}
                        offColor="#ee5253"
                        />
                    </div>

                    <div className="mt-4">
                      <div className="row d-flex justify-content-end align-items-center">
                        <div className="col-3">
                          <button
                            type="submit"
                            className="btn btn-success w-100 xsm-text text-uppercase t-width-max rounded"
                          >
                            {!newExpense.edit ? _t(t("Save")) : _t(t("Update"))}
                          </button>
                        </div>
                        <div className="col-3">
                          <button
                            type="button"
                            className="btn btn-primary w-100 xsm-text text-uppercase t-width-max rounded"
                            data-dismiss="modal"
                          >
                            {_t(t("Close"))}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              ) : (
                <div key="fragment2">
                  <div className="text-center text-primary font-weight-bold text-uppercase">
                    {_t(t("Please wait"))}
                  </div>
                  {modalLoading(3)}
                  <div className="mt-4">
                    <div className="row d-flex justify-content-end align-items-center">
                      <div className="col-3">
                        <button
                          type="button"
                          className="btn btn-success w-100 xsm-text text-uppercase t-width-max rounded"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          {!newExpense.edit ? _t(t("Save")) : _t(t("Update"))}
                        </button>
                      </div>
                      <div className="col-3">
                        <button
                          type="button"
                          className="btn btn-primary w-100 xsm-text text-uppercase t-width-max rounded"
                          data-dismiss="modal"
                        >
                          {_t(t("Close"))}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Add modal Ends*/}

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ManageSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div className="fk-scroll--pos-table-menu" data-simplebar>
                  <div className="t-pl-15 t-pr-15">
                    {/* Loading effect */}
                    {/* {newExpense.uploading === true || loading === true ? (
                      tableLoading()
                    ) : ( */}
                      <div key="fragment3">
                        {/* next page data spin loading */}
                        <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                          <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        {/* spin loading ends */}

                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {!searchedExpense.searched
                                    ? _t(t("Expense List"))
                                    : _t(t("Search Result"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6 col-lg-7">
                            <div className="row gx-3 align-items-center">
                              {/* Search customer */}
                              <div className="col-md-9 t-mb-15 mb-md-0">
                                <div className="input-group">
                                  <div className="form-file">
                                    <input
                                      type="text"
                                      className="form-control border-0 form-control--light-1 rounded-left"
                                      placeholder={_t(t("Search")) + ".."}
                                      onChange={handleSearch}
                                    />
                                  </div>
                                  <button
                                    className="btn btn-primary rounded-right"
                                    type="button"
                                  >
                                    <i
                                      className="fa fa-search"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div>
                              </div>

                              {/* Add customer modal trigger button */}
                              <div className="col-md-3 text-md-right">
                                <button
                                  type="button"
                                  className="btn btn-primary xsm-text text-uppercase btn-lg btn-block rounded"
                                  data-toggle="modal"
                                  data-target="#addExpense"
                                  onClick={() => {
                                    handleFormStateEmpty()
                                  }}
                                >
                                  {_t(t("add new"))}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Table */}
                        <div className="">
                          <table className="table table-bordered table-hover min-table-height">
                            <thead className="align-middle">
                              <tr>
                                <th
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                scope="col"
                                >
                                  {_t(t("S/L"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Name"))}
                                </th>

                                {/* <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Associate with"))}
                                </th> */}
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Status"))}
                                </th>

                                {/* <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Due"))}
                                </th> */}

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Action"))}
                                </th>
                              </tr>
                            </thead>
                            <tbody className="align-middle">
                              {/* loop here, logic === !search && haveData && haveDataLegnth > 0*/}
                              {!searchedExpense.searched
                                ? [
                                    expense && [
                                      (expense.data === null || expense.data?.length === 0) ? (
                                        <tr className="align-middle">
                                          <td
                                            scope="row"
                                            colSpan="7"
                                            className="xsm-text align-middle text-center"
                                          >
                                            {_t(t("No data available"))}
                                          </td>
                                        </tr>
                                      ) : (
                                         expense.data?.map((item, index) => {
                                          console.log("List Item ", item);
                                          return (
                                            <tr
                                              className="align-middle"
                                              key={index}
                                            >
                                              <th
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                              >
                                                {index +
                                                  1 +
                                                  (expense.current_page - 1) *
                                                    expense.per_page}
                                              </th>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {item.name}
                                              </td>
                                           
                                              <td className="sm-text text-capitalize align-middle text-center ">
                                                <Switch
                                                checked={item?.status === 1}
                                                name="status"
                                                onChange={(e) => {
                                                  handleUpdateStatus(item)
                                                
                                                }}
                                                height={22}
                                                width={44}
                                                offColor="#ee5253"
                                                />
                                                {/*item.show_header === "1" ? (
                                                  <span
                                                    style={{
                                                      color: "Green",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    ON
                                                  </span>
                                                ) : (
                                                  <span
                                                    style={{
                                                      color: "Red",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    OFF
                                                  </span>
                                                  )*/}
                                              </td>

                                              {/* <td className="xsm-text align-middle text-center">
                                                {currencySymbolLeft()}
                                                {formatPrice(item.due_balance)}
                                                {currencySymbolRight()}
                                              </td> */}

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {/* <div className="dropdown">
                                                  <button
                                                    className="btn t-bg-clear t-text-dark--light-40"
                                                    type="button"
                                                    data-toggle="dropdown"
                                                  >
                                                    <i className="fa fa-ellipsis-h"></i>
                                                  </button>
                                                  <div className="dropdown-menu">
                                                    <button
                                                      className="dropdown-item sm-text text-capitalize"
                                                      onClick={() => {
                                                        setNewExpense({
                                                          ...newExpense,
                                                        });
                                                        handleSetEdit(
                                                          item.slug
                                                        );
                                                      }}
                                                      data-toggle="modal"
                                                      data-target="#addExpense"
                                                    >
                                                      <span className="t-mr-8">
                                                        <i className="fa fa-pencil"></i>
                                                      </span>
                                                      {_t(t("Edit"))}
                                                    </button>

                                                    <button
                                                      className="dropdown-item sm-text text-capitalize"
                                                      onClick={() => {
                                                        handleDeleteConfirmation(
                                                          item.slug
                                                        );
                                                      }}
                                                    >
                                                      <span className="t-mr-8">
                                                        <i className="fa fa-trash"></i>
                                                      </span>
                                                      {_t(t("Delete"))}
                                                    </button>
                                                  </div>
                                                </div> */}
                                                <button
                                                  className="btn btn-primary ml-2 rounded-sm del-btn"
                                                  onClick={() => {
                                                    setNewExpense({
                                                      ...newExpense,
                                                    });
                                                    handleSetEdit(
                                                      item.slug
                                                    );
                                                  }}
                                                  data-toggle="modal"
                                                  data-target="#addExpense"                                                    
                                                  >                                                    
                                                    <i className="fa fa-pencil edit-icon" aria-hidden="true"></i>                                                    
                                                 </button>
                                                  <button
                                                  className="btn btn-danger ml-2 rounded-sm del-btn"
                                                  onClick={()=>handleDeleteConfirmation(item.slug)}                                                    
                                                  >                                                    
                                                    <MdDelete className="del-icon" />                                                    
                                                 </button>
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ),
                                    ],
                                  ]
                                : [
                                    /* searched data, logic === haveData*/
                                    searchedExpense && [
                                      (expense.data === null || expense.data?.length === 0) ? (
                                        <tr className="align-middle">
                                          <td
                                            scope="row"
                                            colSpan="7"
                                            className="xsm-text align-middle text-center"
                                          >
                                            {_t(t("No data available"))}
                                          </td>
                                        </tr>
                                      ) : (
                                        searchedExpense.list.map(
                                          (item, index) => {
                                            return (
                                              <tr
                                                className="align-middle"
                                                key={index}
                                              >
                                                <th
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {index +
                                                    1 +
                                                    (expense.current_page -
                                                      1) *
                                                      expense.per_page}
                                                </th>

                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  {item.name}
                                                </td>

                                                <td className="sm-text text-capitalize align-middle text-center ">
                                                  <Switch
                                                  checked={item?.status === 1}
                                                  name="status"
                                                  onChange={(e) => {
                                                    handleUpdateStatus(item)
                                                  
                                                  }}
                                                  height={22}
                                                  width={44}
                                                  offColor="#ee5253"
                                                  />
                                                  {/*item.show_header === "1" ? (
                                                    <span
                                                      style={{
                                                        color: "Green",
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      ON
                                                    </span>
                                                  ) : (
                                                    <span
                                                      style={{
                                                        color: "Red",
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      OFF
                                                    </span>
                                                    )*/}
                                                </td>
                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  {/* <div className="dropdown">
                                                    <button
                                                      className="btn t-bg-clear t-text-dark--light-40"
                                                      type="button"
                                                      data-toggle="dropdown"
                                                    >
                                                      <i className="fa fa-ellipsis-h"></i>
                                                    </button>
                                                    <div className="dropdown-menu">
                                                      <button
                                                        className="dropdown-item sm-text text-capitalize"
                                                        onClick={() => {
                                                          setNewExpense({
                                                            ...newExpense,
                                                          });
                                                          handleSetEdit(
                                                            item.slug
                                                          );
                                                        }}
                                                        data-toggle="modal"
                                                        data-target="#addExpense"
                                                      >
                                                        <span className="t-mr-8">
                                                          <i className="fa fa-pencil"></i>
                                                        </span>
                                                        {_t(t("Edit"))}
                                                      </button>

                                                      <button
                                                        className="dropdown-item sm-text text-capitalize"
                                                        onClick={() => {
                                                          handleDeleteConfirmation(
                                                            item.slug
                                                          );
                                                        }}
                                                      >
                                                        <span className="t-mr-8">
                                                          <i className="fa fa-trash"></i>
                                                        </span>
                                                        {_t(t("Delete"))}
                                                      </button>
                                                    </div>
                                                  </div> */}
                                                  <button
                                                  className="btn btn-primary ml-2 rounded-sm del-btn"
                                                  onClick={() => {
                                                    setNewExpense({
                                                      ...newExpense,
                                                    });
                                                    handleSetEdit(
                                                      item.slug
                                                    );
                                                  }}
                                                  data-toggle="modal"
                                                  data-target="#addExpense"                                                    
                                                  >                                                    
                                                    <i className="fa fa-pencil edit-icon" aria-hidden="true"></i>                                                    
                                                 </button>
                                                  <button
                                                  className="btn btn-danger ml-2 rounded-sm del-btn"
                                                  onClick={()=>handleDeleteConfirmation(item.slug)}                                                    
                                                  >                                                    
                                                    <MdDelete className="del-icon" />                                                    
                                                 </button>
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )
                                      ),
                                    ],
                                  ]}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    {/* )} */}
                  </div>
                </div>
              </div>

              {/* pagination loading effect */}
              {newExpense.uploading === true || loading === true
                ? paginationLoading()
                : [
                    // logic === !searched
                    !searchedExpense.searched ? (
                      <div key="fragment4">
                        <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                          <div className="row align-items-center t-pl-15 t-pr-15">
                            <div className="col-md-7 t-mb-15 mb-md-0">
                              {/* pagination function */}
                              {pagination(expense, getPaginatedExpense)}
                            </div>
                            <div className="col-md-5">
                              <ul className="t-list d-flex justify-content-md-end align-items-center">
                                <li className="t-list__item">
                                  <span className="d-inline-block sm-text">
                                    {showingData(expense)}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      // if searched
                      <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                        <div className="row align-items-center t-pl-15 t-pr-15">
                          <div className="col-md-7 t-mb-15 mb-md-0">
                            <ul className="t-list d-flex">
                              <li className="t-list__item no-pagination-style">
                                <button
                                  className="btn btn-primary btn-sm"
                                  onClick={() =>
                                    setsearchedExpense({
                                      ...searchedExpense,
                                      searched: false,
                                    })
                                  }
                                >
                                  {_t(t("Clear Search"))}
                                </button>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-5">
                            <ul className="t-list d-flex justify-content-md-end align-items-center">
                              <li className="t-list__item">
                                <span className="d-inline-block sm-text">
                                  {searchedShowingData(
                                    searchedExpense,
                                    expenseForSearch
                                  )}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    ),
                  ]}
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default Expense;
